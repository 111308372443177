/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faListUl,
  faBook,
} from "@fortawesome/free-solid-svg-icons";
import { faFileExcel } from "@fortawesome/free-regular-svg-icons";
import ViLanguages from "../../../../languages/vi";
import { faMap } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
import LeftMenuModels from "../../../../models/planning-map-view/left-menu.model";
import { connect } from "react-redux";
import LayerCategoryModels from "../../../../models/map-data-model-b/layer-category-models";
import LayerSettingsModels from "../../../../models/map-data-model-b/layer-setting-models";
import { bindActionCreators } from "redux";
import * as MapDataStore from "../../../../redux/store/map-data/map-data.store";
import * as MapToolDataStore from "../../../../redux/store/map-tool/map-tool.store";
import * as projectAction from '../../../../redux/store/housing-estate/housing-estate.store'
import PlanningRelationModel, {
  PlanningMapModels,
  BoundariesViewModel, RelatedLayerModel
} from "../../../../models/map-data-model-b/new-planning-relation.model";

import ProjectDetail from "../../left-menu/info/project-detail";
import InfoPlanning from "../../left-menu/info/planning"
import { Tooltip } from '@material-ui/core';

import ReactTooltip from "react-tooltip";
import ShowNotification from "../../../../components/react-notifications/react-notifications";
import {
  NotificationMessageType, getCookies,
} from "../../../../utils/configuration";
import { isMobile } from "react-device-detect";
const LanguageCollects = ViLanguages;

function MapLayer(props: LeftMenuModels.LeftMenuProps) {
  const { planningModel } = props;
  const [isShowMapResult, setShowMapResult] = useState(false);
  const [headerTable, setHeaderTable] = useState<any>();

  function handleClickToggleLeftNavBar() {
    // props.toggleStateIsLeftNavbarHide();
    // if (!props.isLeftNavbarHide) {
    //   document
    //     .getElementsByClassName("left-custom-navbar")[0]
    //     .classList.add("hide-left-navbar");
    // } else {
    //   document
    //     .getElementsByClassName("left-custom-navbar")[0]
    //     .classList.remove("hide-left-navbar");
    // }
  }

  function handleToggleDisplayLayer(
    layerTarget: LayerSettingsModels.LayerSettingsModel
  ) {
    props.openLayerController?.toggleDisplayLayer(layerTarget);
  }

  function handleToggleDisplayBoundaries(
    boundariesObject: BoundariesViewModel,
    relatedLayerObject: RelatedLayerModel,
    isDisplay: boolean
  ) {
    props.openLayerController.toggleDisplayLayerRelated(
      boundariesObject,
      relatedLayerObject,
      isDisplay
    );
  }


  const [isAdmin, setIsAdmin] = useState(false);
  const getAdmin = getCookies('IS_ADMIN');
  useEffect(() => {
    setIsAdmin(getCookies('IS_ADMIN') === 'true' ? true : false)
    console.log(getCookies('IS_ADMIN') === 'true' ? true : false)
  }, [])
  useEffect(() => {
    setIsAdmin(getAdmin === 'true' ? true : false);
  }, [getCookies('IS_ADMIN')])

  // when IsLeftNavbarHide


  return (
    < >
      <div
        id="mapLayersTab"
        className="collapse show"
        aria-labelledby="headingOne"
        data-parent="#accordion"
      >
        {props.dataToggleLayer.map((data, index: number) => (
          <RenderMapChild
            toggleLayerAction={(
              data: LayerSettingsModels.LayerSettingsModel
            ) => handleToggleDisplayLayer(data)}
            updateDataToggleLayer={props.UpdateDataToggleLayer}
            toggleLeftNavBarAction={() => handleClickToggleLeftNavBar()}
            key={index}
            groupIndex={index}
            data={data}
            {...props}
          />
        ))}
      </div>
      {/* <div
        id="mapInfomationTab"
        className="collapse"
        aria-labelledby="headingTwo"
        data-parent="#accordion"
      >
        <ProjectDetail
          listMapInfomations={props.listMapInfomations}
          listMapInfomationsRelated={props.listMapInfomationsRelated}
          listMapInfomationsRelatedTable={props.listMapInfomationsRelatedTable}
        />
      </div>
      <div
        id="relativePlanningTab"
        className="collapse"
        aria-labelledby="headingThree"
        data-parent="#accordion"
      >
        <InfoPlanning
          listMapInfomations={props.listMapInfomations}
          listMapInfomationsRelated={props.listMapInfomationsRelated}
          listMapInfomationsRelatedTable={props.listMapInfomationsRelatedTable}
        />
      </div> */}
    </>
  );
}


function RenderMapChild(props: any) {
  const data: LayerCategoryModels.LayerCategoryModel = props.data;
  const [layerSetting, setLayerSetting] = useState<
    LayerSettingsModels.LayerSettingsModel[]
  >(props.data.layer_settings);
  const [labelFlexible, setLabelFlexible] = useState("Chọn tất");

  function toggleSelectAll() {
    if (isEveryCheckboxChecked()) {
      selectOrDeselectAllCheckBox(false);
    } else {
      selectOrDeselectAllCheckBox();
    }
  }

  function isEveryCheckboxChecked() {
    let result = true;
    const listCheckbox = Array.from(
      document.querySelectorAll(`input[data-select-id="${data.folder_name}"]`)
    );
    listCheckbox.map((checkbox) => {
      if (!(checkbox as HTMLInputElement).checked) {
        result = false;
        return true;
      }
    });
    return result;
  }
  function selectOrDeselectAllCheckBox(isSelect = true) {
    const listCheckbox = Array.from(
      document.querySelectorAll(`input[data-select-id="${data.folder_name}"]`)
    );
    listCheckbox.map((checkboxElement) => {
      const checkbox = checkboxElement as HTMLInputElement;
      if (checkbox.checked != isSelect) checkbox.click();
    });
  }

  function handleClickCheckbox(LayerIndex: number, newValue: boolean) {
    const checkboxAll = document.querySelectorAll(
      `input[data-select-all-id="${data.folder_name}"]`
    )[0] as HTMLInputElement;
    const oldLayerSetting = [...layerSetting];
    oldLayerSetting[LayerIndex].is_check = newValue;
    setLayerSetting(oldLayerSetting);
    props.toggleLayerAction(oldLayerSetting[LayerIndex]);
    props.updateDataToggleLayer(oldLayerSetting[LayerIndex]);
    if (checkboxAll) {
      if (isEveryCheckboxChecked()) {
        checkboxAll.checked = true;
        setLabelFlexible("Bỏ chọn tất cả");
      } else {
        checkboxAll.checked = false;
        setLabelFlexible("Chọn tất");
      }
    }
    if (isMobile) {
      props.toggleLeftNavBarAction();
    }
  }

  const handleChangeViewList = (index: number, key: number) => {
    console.log('dkjfgsdjhfsd', key)
    if (props.currentSelected.type == key + 1) {
      props.setCurrentProjectSelect(
        key + 1,
        !props.currentSelected.show
      )
      !props.currentSelected.show && handleClickCheckbox(index, true)
    } else {
      props.setCurrentProjectSelect(
        key + 1,
        true
      )
      handleClickCheckbox(index, true)
    }
  }
  return (
    <div className="render-map-child-container pt-2 pl-2 pr-2">
      <details open>
        <summary className="mb-1">
          {data.folder_name}
        </summary>
        <div className="select-container">
          {data.layer_settings.length > 1 && (
            <label className="d-block">
              <input
                type="checkbox"
                className="mr-1"
                data-select-all-id={data.folder_name}
                onClick={() => toggleSelectAll()}
              />
              {labelFlexible}
            </label>
          )}
          {layerSetting.map((item, index: number) => (
            <div className="group_layer">
              <label key={index} className="d-block">
                <input
                  type="checkbox"
                  data-select-id={data.folder_name}
                  checked={item.is_check}
                  onChange={() => { }}
                  data-left-menu-layer-id={item.name}
                  className="mr-1"
                  onClick={() => handleClickCheckbox(index, !item.is_check)}
                />
                {item.name}
              </label>

              <Tooltip
                title={(props.currentSelected.type == props.groupIndex + 1) && props.currentSelected.show ? 'Ẩn danh sách' : 'Xem danh sách'}
                onClick={() => handleChangeViewList(index, props.groupIndex)}
              >
                <div>
                  <FontAwesomeIcon
                    icon={faListUl}
                    color={(props.currentSelected.type == props.groupIndex + 1) && props.currentSelected.show ? '#00923f' : '#979898'}
                    size="lg"
                  />
                </div>
              </Tooltip>
            </div>
          ))}
        </div>
      </details>
    </div>
  );
}


const mapStateToProps = (state: any) => ({
  listMapInfomations: state.mapData.infomations,
  listMapInfomationsRelated: state.mapData.infomationsRelated,
  listMapInfomationsRelatedTable: state.mapData.infomationsRelatedTable,
  informationForTableResult: state.mapData.dataForTable,
  listPlanningRelationShips: state.mapData.planningRelationShips,
  listPlanningBoundaries: state.mapData.listPlanningBoundaries,
  openLayerController: state.openlayer.openLayerController,
  headerTable: state.mapData.headerTable,
  dataToggleLayer: state.mapToolData.dataToggleLayer,
  currentSelected: state.currentProjectSelected.currentProjectSelect,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      GetListPlanningRelationByPlanningId:
        MapDataStore.GetListPlanningRelationByPlanningId,
      UpdateDataToggleLayer: MapToolDataStore.UpdateDataToggleLayer,
      setCurrentProjectSelect: projectAction.setCurrentProjectSelect,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MapLayer);
