export const checkIsProject = (value = '') => {
    if (!value) return
    const arrValue = value.split('.');
    let isProject = 0;
    switch (arrValue[0]) {
        case 'housing_project':
            isProject = 1;
            break;
        case 'real_estate_project':
            isProject = 2;
            break;
        case 'construction_real_estate':
            isProject = 3;
            break;
        case 'real_estate_exchange_layer':
            isProject = 4;
            break;
        default:
            isProject = 0;
    }
    return {type: isProject, id: arrValue[1]};
}