import React, { useState, useRef, useEffect } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faListUl, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import Slider from "react-slick";
import LeftMenuView from '../left-menu/left-menu.view';
import InfoPlanning from '../left-menu/info/planning'
import MapLayer from './tabs/map-layer';
import ListFooterView from '../list-footer/list-footer.view';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActions from "../../../core/app.store";
import * as projectAction from '../../../redux/store/housing-estate/housing-estate.store'
import ProjectDetail from '../left-menu/info/project-detail';

const listMenu = [
    {
        id: 0,
        name: 'Bản đồ',
    },
    {
        id: 1,
        name: 'Danh sách',
    },
    {
        id: 2,
        name: 'Thông tin chi tiết',
    },
    {
        id: 3,
        name: 'Thông tin khác',
    },
]

const MenuMobile = (props) => {
    const ref = useRef({});

    const [isOpen, setIsopen] = useState(false);
    const [currentIdex, setCurrentIdex] = useState(0)

    const sliderSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        autoplay: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        // afterChange: () => setCurrentIdex(updateCount + 1),
        beforeChange: (current, next) => setCurrentIdex(next)
    };

    const goToIndex = (index) => {
        if (ref.current && ref.current.slickGoTo) ref.current.slickGoTo(index);
    }

    useEffect(() => {
        if (props.currentSelected.show) {
            setIsopen(true);
            goToIndex(1)
        }
    }, [props.currentSelected])

    useEffect(() => {
        if (props.currentDetailSelected.data?.id) {
            setIsopen(true);
            goToIndex(2)
        }
    }, [props.currentDetailSelected])

    useEffect(() => {
        if (props.listMapInfomations.length > 0) {
            setIsopen(true);
            goToIndex(3)
        }
    }, [props.listMapInfomations])

    return (
        <div className='menu_mobile'>
            <div className='btn_menu'>
                <FontAwesomeIcon
                    icon={faListUl}
                    color="white"
                    onClick={() => setIsopen(true)}
                />
            </div>
            <div className={`menu_mobile_content ${isOpen ? 'active' : ''}`}>
                {isOpen && (
                    <div className='btn_close' onClick={() => setIsopen(false)}>
                        <FontAwesomeIcon
                            icon={faArrowDown}
                            color="white"
                            onClick={() => setIsopen(false)}
                        />
                    </div>
                )}
                <div className='header_content'>

                    <div className='header_menu'>
                        {listMenu.map(item => (
                            <div
                                key={item.id}
                                className={`menu_item ${item.id == currentIdex ? 'active' : ''}`}
                                onClick={() => {
                                    goToIndex(item.id);
                                    setCurrentIdex(item.id)
                                }}
                            >
                                {item.name}
                            </div>
                        ))}
                    </div>
                </div>
                <div>
                    <Slider ref={ref} {...sliderSettings} className="image-show slick_slider_custom">
                        <div>
                            {props.hasLoadedMapData && props.mapData.name && (
                                <MapLayer
                                    planningModel={props.planningModel}
                                    planningId={props.mapPlanning}
                                    listGroupLayer={props.dataToggleLayer}
                                    isLeftNavbarHide={props.isLeftNavbarHide}
                                    toggleStateIsLeftNavbarHide={props.toggleStateIsLeftNavbarHide}
                                />
                            )}
                        </div>
                        <div>
                            <ListFooterView isMobile />
                        </div>
                        <div>
                            <ProjectDetail 
                                isMobile
                            />
                        </div>
                        <div className='p-2'>
                            <InfoPlanning
                                isMobile
                                listMapInfomations={props.listMapInfomations}
                                listMapInfomationsRelated={props.listMapInfomationsRelated}
                                listMapInfomationsRelatedTable={props.listMapInfomationsRelatedTable}
                            />
                        </div>
                    </Slider>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
    listMapInfomations: state.mapData.infomations,
    listMapInfomationsRelated: state.mapData.infomationsRelated,
    listMapInfomationsRelatedTable: state.mapData.infomationsRelatedTable,
    currentSelected: state.currentProjectSelected.currentProjectSelect,
    currentDetailSelected: state.currentProjectSelected.detailCurrentProjectSelect
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
            setCurrentProjectSelect: projectAction.setCurrentProjectSelect,
            setDetailProjectSelect: projectAction.setDetailProjectSelect,
        },
        dispatch
    );

export default (connect(mapStateToProps, mapDispatchToProps)(MenuMobile));
