export const fixedDataGroupLayersGeneralWithDetail = {
  wms: "https://geo.cgis.asia/geoserver/pleiku/wms/",
  giaothong : [
    {typeId: 2, typeName: "Quy hoạch chung", tableName: "pleiku_qhc_giaothong", isChecked: true, isAddedLayer: true, style: 'Line'},
    {typeId: 1, typeName: "Quy hoạch phân khu", tableName: "pleiku_qhpk_giaothong", isChecked: true, isAddedLayer: true, style: 'Line'},
    {typeId: 3, typeName: "Quy hoạch chi tiết", tableName: "pleiku_qhct_giaothong", isChecked: true, isAddedLayer: true, style: 'Line'},
  ],
  sdd: [
    {typeId: 2, typeName: "Quy hoạch chung", tableName: "pleiku_qhc_sdd", isChecked: true, isAddedLayer: true, style: 'Polygon'},
    {typeId: 1, typeName: "Quy hoạch phân khu", tableName: "pleiku_qhpk_sdd", isChecked: true, isAddedLayer: true, style: 'Polygon'},
    {typeId: 3, typeName: "Quy hoạch chi tiết", tableName: "pleiku_qhct_sdd", isChecked: true, isAddedLayer: true, style: 'Polygon'},
  ],
}

export const fixedDataGroupLayersPlanningView = {
  wms: "https://geo.cgis.asia/geoserver/pleiku/wms/",
  giaothong : [
    {typeId: 2, typeName: "Quy hoạch chung", tableName: "pleiku_qhc_giaothong", isChecked: false, isAddedLayer: false, style: 'Line'},
    {typeId: 1, typeName: "Quy hoạch phân khu", tableName: "pleiku_qhpk_giaothong", isChecked: false, isAddedLayer: false, style: 'Line'},
    {typeId: 3, typeName: "Quy hoạch chi tiết", tableName: "pleiku_qhct_giaothong", isChecked: false, isAddedLayer: false, style: 'Line'},
  ],
  sdd: [
    {typeId: 2, typeName: "Quy hoạch chung", tableName: "pleiku_qhc_sdd", isChecked: false, isAddedLayer: false, style: 'Polygon'},
    {typeId: 1, typeName: "Quy hoạch phân khu", tableName: "pleiku_qhpk_sdd", isChecked: false, isAddedLayer: false, style: 'Polygon'},
    {typeId: 3, typeName: "Quy hoạch chi tiết", tableName: "pleiku_qhct_sdd", isChecked: false, isAddedLayer: false, style: 'Polygon'},
  ],
}

export const fixedDisplayNameQHC_SDD = {
  "cols": [
      {
          "col": "gid",
          "kieu": "integer",
          "alias": "gid",
          "index": 0
      },
      {
          "col": "loaidat",
          "kieu": "character varying",
          "alias": "Loại đất",
          "index": 1
      },
      {
          "col": "phanloai",
          "kieu": "character varying",
          "alias": "Phân loại",
          "index": 2
      },
      {
          "col": "layer",
          "kieu": "character varying",
          "alias": "layer",
          "index": 3
      },
      {
          "col": "geom",
          "kieu": "USER-DEFINED",
          "alias": "geom",
          "index": 4
      },
      {
          "col": "ten_loai_dat",
          "kieu": "character varying",
          "alias": "ten_loai_dat",
          "index": 5
      },
      {
          "col": "chu_giai",
          "kieu": "character varying",
          "alias": "chu_giai",
          "index": 6
      }
  ],
  "viewdetail": {
      "use": true,
      "cols": [
          "loaidat",
          "phanloai",
          "ten_loai_dat",
          "chu_giai"
      ]
  }
}

export const fixedDisplayNameQHC_GIAOTHONG = {
  "cols": [
      {
          "col": "gid",
          "kieu": "integer",
          "alias": "gid",
          "index": 0
      },
      {
          "col": "loaiduong",
          "kieu": "character varying",
          "alias": "Loại đường",
          "index": 1
      },
      {
          "col": "tenduong",
          "kieu": "character varying",
          "alias": "Tên đường",
          "index": 2
      },
      {
          "col": "kyhieu",
          "kieu": "character varying",
          "alias": "Ký hiệu",
          "index": 3
      },
      {
          "col": "phanloai",
          "kieu": "character varying",
          "alias": "Phân loại",
          "index": 4
      },
      {
          "col": "chigioi",
          "kieu": "character varying",
          "alias": "Chỉ giới",
          "index": 5
      },
      {
          "col": "longduong",
          "kieu": "character varying",
          "alias": "Lòng đường",
          "index": 6
      },
      {
          "col": "viahe",
          "kieu": "character varying",
          "alias": "Vỉa hè",
          "index": 7
      },
      {
          "col": "phancach",
          "kieu": "character varying",
          "alias": "Phân cách",
          "index": 8
      },
      {
          "col": "duonggom",
          "kieu": "character varying",
          "alias": "Đường gom",
          "index": 9
      },
      {
          "col": "duongsat",
          "kieu": "character varying",
          "alias": "Đường sắt",
          "index": 10
      },
      {
          "col": "geom",
          "kieu": "USER-DEFINED",
          "alias": "geom",
          "index": 11
      },
      {
          "col": "ten_loai_dat",
          "kieu": "character varying",
          "alias": "Tên loại đất",
          "index": 12
      },
      {
          "col": "chu_giai",
          "kieu": "character varying",
          "alias": "Chú giải",
          "index": 13
      }
  ],
  "viewdetail": {
      "use": true,
      "cols": [
          "loaiduong",
          "tenduong",
          "kyhieu",
          "phanloai",
          "chigioi",
          "longduong",
          "viahe",
          "phancach",
          "duonggom",
          "duongsat",
          "ten_loai_dat",
          "chu_giai"
      ]
  }
}

export const fixedDisplayNameQHPK_SDD = {
    "cols": [
        {
            "col": "gid",
            "kieu": "integer",
            "alias": "gid",
            "index": 0
        },
        {
            "col": "loaidat",
            "kieu": "character varying",
            "alias": "Loại đất",
            "index": 1
        },
        {
            "col": "layer",
            "kieu": "character varying",
            "alias": "layer",
            "index": 2
        },
        {
            "col": "hesosdd",
            "kieu": "character varying",
            "alias": "Hệ số SDĐ",
            "index": 3
        },
        {
            "col": "matdoxd",
            "kieu": "character varying",
            "alias": "Mật độ xây dựng",
            "index": 4
        },
        {
            "col": "dienticho",
            "kieu": "character varying",
            "alias": "Diện tích",
            "index": 5
        },
        {
            "col": "kihieuo",
            "kieu": "character varying",
            "alias": "Kí hiệu",
            "index": 6
        },
        {
            "col": "tangcao",
            "kieu": "character varying",
            "alias": "Tầng cao",
            "index": 7
        },
        {
            "col": "danso",
            "kieu": "character varying",
            "alias": "Dân số",
            "index": 8
        },
        {
            "col": "dientich",
            "kieu": "double precision",
            "alias": "Diện tích",
            "index": 9
        },
        {
            "col": "geom",
            "kieu": "USER-DEFINED",
            "alias": "geom",
            "index": 10
        },
        {
            "col": "ten_loai_dat",
            "kieu": "character varying",
            "alias": "Tên loại đất",
            "index": 11
        },
        {
            "col": "chu_giai",
            "kieu": "character varying",
            "alias": "Chú giải",
            "index": 12
        }
    ],
    "viewdetail": {
        "use": true,
        "cols": [
            "loaidat",
            "hesosdd",
            "matdoxd",
            "kihieuo",
            "tangcao",
            "danso",
            "dientich",
            "ten_loai_dat",
            "chu_giai"
        ]
    }
}

export const fixedDisplayNameQHPK_GIAOTHONG = {
    "cols": [
        {
            "col": "gid",
            "kieu": "integer",
            "alias": "gid",
            "index": 0
        },
        {
            "col": "tenduong",
            "kieu": "character varying",
            "alias": "Tên đường",
            "index": 1
        },
        {
            "col": "phanloai",
            "kieu": "character varying",
            "alias": "Phân loại",
            "index": 2
        },
        {
            "col": "kihieu",
            "kieu": "character varying",
            "alias": "Kí hiệu",
            "index": 3
        },
        {
            "col": "chigioi",
            "kieu": "character varying",
            "alias": "Chỉ giới",
            "index": 4
        },
        {
            "col": "longduong",
            "kieu": "character varying",
            "alias": "Lòng đường",
            "index": 5
        },
        {
            "col": "viahe",
            "kieu": "character varying",
            "alias": "Vỉa hè",
            "index": 6
        },
        {
            "col": "daiphancac",
            "kieu": "character varying",
            "alias": "Dải phân cách",
            "index": 7
        },
        {
            "col": "layer",
            "kieu": "character varying",
            "alias": "layer",
            "index": 8
        },
        {
            "col": "chieudai",
            "kieu": "double precision",
            "alias": "Chiều dài",
            "index": 9
        },
        {
            "col": "geom",
            "kieu": "USER-DEFINED",
            "alias": "geom",
            "index": 10
        },
        {
            "col": "ten_loai_dat",
            "kieu": "character varying",
            "alias": "Tên loại đất",
            "index": 11
        },
        {
            "col": "chu_giai",
            "kieu": "character varying",
            "alias": "Chú giải",
            "index": 12
        }
    ],
    "viewdetail": {
        "use": true,
        "cols": [
            "tenduong",
            "phanloai",
            "kihieu",
            "chigioi",
            "longduong",
            "viahe",
            "chieudai"
        ]
    }
}

export const fixedDisplayNameQHCT_SDD = {
    "cols": [
        {
            "col": "gid",
            "kieu": "integer",
            "alias": "gid",
            "index": 0
        },
        {
            "col": "malienket",
            "kieu": "character varying",
            "alias": "malienket",
            "index": 1
        },
        {
            "col": "makhuqh",
            "kieu": "character varying",
            "alias": "makhuqh",
            "index": 2
        },
        {
            "col": "danso",
            "kieu": "character varying",
            "alias": "danso",
            "index": 3
        },
        {
            "col": "kyhieuoqh",
            "kieu": "character varying",
            "alias": "kyhieuoqh",
            "index": 4
        },
        {
            "col": "madoanqh",
            "kieu": "character varying",
            "alias": "madoanqh",
            "index": 5
        },
        {
            "col": "matdoxdt_1",
            "kieu": "character varying",
            "alias": "Mật độ xây dựng tối đa(%)",
            "index": 6
        },
        {
            "col": "tangcaot_1",
            "kieu": "character varying",
            "alias": "Chiều cao tối đa",
            "index": 7
        },
        {
            "col": "dientich",
            "kieu": "numeric",
            "alias": "Diện tích",
            "index": 8
        },
        {
            "col": "loaidat",
            "kieu": "character varying",
            "alias": "Tên loại đất",
            "index": 9
        },
        {
            "col": "chucnangsd",
            "kieu": "character varying",
            "alias": "chucnangsd",
            "index": 10
        },
        {
            "col": "hesosdd",
            "kieu": "numeric",
            "alias": "Hệ số sử dụng đất tối đa(lần)",
            "index": 11
        },
        {
            "col": "dientichsa",
            "kieu": "numeric",
            "alias": "dientichsa",
            "index": 12
        },
        {
            "col": "solo",
            "kieu": "numeric",
            "alias": "Số hiệu lô",
            "index": 13
        },
        {
            "col": "ma",
            "kieu": "character varying",
            "alias": "ma",
            "index": 14
        },
        {
            "col": "geom",
            "kieu": "USER-DEFINED",
            "alias": "geom",
            "index": 15
        },
        {
            "col": "ten_loai_dat",
            "kieu": "character varying",
            "alias": "Tên loại đất",
            "index": 16
        },
        {
            "col": "chu_giai",
            "kieu": "character varying",
            "alias": "Chú giải",
            "index": 17
        }
    ],
    "viewdetail": {
        "use": true,
        "cols": [
            "matdoxdt_1",
            "tangcaot_1",
            "dientich",
            "loaidat",
            "hesosdd",
            "solo"
        ]
    }
}

export const fixedDisplayNameQHCT_GIAOTHONG = {
    "cols": [
        {
            "col": "gid",
            "kieu": "integer",
            "alias": "gid",
            "index": 0
        },
        {
            "col": "tenduong",
            "kieu": "character varying",
            "alias": "Tên đường",
            "index": 1
        },
        {
            "col": "matcat",
            "kieu": "character varying",
            "alias": "matcat",
            "index": 2
        },
        {
            "col": "chigioi",
            "kieu": "character varying",
            "alias": "Chỉ giới đường đỏ(m)",
            "index": 3
        },
        {
            "col": "longduong",
            "kieu": "character varying",
            "alias": "Lòng đường(m)",
            "index": 4
        },
        {
            "col": "viahe",
            "kieu": "character varying",
            "alias": "Vỉa hè(m)",
            "index": 5
        },
        {
            "col": "phancach",
            "kieu": "character varying",
            "alias": "Phân cách",
            "index": 6
        },
        {
            "col": "chieudai",
            "kieu": "numeric",
            "alias": "Chiều dài(m)",
            "index": 7
        },
        {
            "col": "geom",
            "kieu": "USER-DEFINED",
            "alias": "geom",
            "index": 8
        },
        {
            "col": "ten_loai_dat",
            "kieu": "character varying",
            "alias": "ten_loai_dat",
            "index": 9
        },
        {
            "col": "chu_giai",
            "kieu": "character varying",
            "alias": "chu_giai",
            "index": 10
        }
    ],
    "viewdetail": {
        "use": true,
        "cols": [
            "tenduong",
            "chigioi",
            "longduong",
            "viahe",
            "phancach",
            "chieudai"
        ]
    }
}