import dateformat from "dateformat";

export const getProjectTypeName = (typeId) => {
    let name = '';
    switch (typeId) {
        case 1:
            name = 'Dự án Nhà ở'
            break;
        case 2:
            name = 'Dự án Bất động sản'
            break;
        case 3:
            name = 'Công trình Bất động sản'
            break;
        case 4:
            name = 'Sàn giao dịch Bất động sản'
            break;
        default:
            name = ''
    }
    return name
}

export const showTabDetailProject = () => {
    const mapInfomationTab = document.getElementById(
        "ButtonControlProjectInfomationTab"
    );
    const leftSidebar = document.getElementById('left-custom-navbar')
    const btnShowInfo = document.getElementById('btn_show_info')
    if (mapInfomationTab) {
        if (mapInfomationTab.classList.contains("collapsed"))
            mapInfomationTab.click();
        if(leftSidebar.classList.contains("hide-left-navbar"))
            btnShowInfo.click()
    }
}

const aliasKey = (key) => {
    switch (key) {
        case 'address':
            return 'Địa chỉ';
        case 'realEstateExchangeName':
            return 'Tên sàn';
        case 'dateEstablishment':
            return 'Ngày thành lập';
        case 'dateDissolution':
            return 'Ngày giải thể';
        case 'legalRepresentative':
            return 'Đại diện';
        case 'areaSize':
            return 'Quy mô diện tích';
        case 'investorName':
            return 'Chủ đầu tư';
        case 'constructionCategoryName':
            return 'Loại công trình';
        case 'approvalUnit':
            return 'Đơn vị cấp phép';
        case 'areaCommercialSpace':
            return 'Diện tích mặt bằng thương mại (m2)';
        case 'areaHouse':
            return 'Diện tích nhà ở (m2)';
        case 'areaHouseProvided':
            return 'Diện tích nhà ở được cấp GCN ';
        case 'areaOfficeLease':
            return 'Diện tích văn phòng cho thuê (m2)';
        case 'areaTypeName':
            return 'Khu vực';
        case 'averageRentalPrice':
            return 'Giá thuê bình quân';
        case 'averageSalesPrice':
            return 'Giá bán bình quân';
        case 'constructionLicense':
            return 'Cấp phép xây dựng';
        case 'constructionLicensingUnit':
            return 'Đơn vị cấp phép';
        case 'executionTime':
            return 'Thời gian thực hiện';
        case 'investorName':
            return 'Chủ đầu tư';
        case 'numberHouse':
            return 'Số lượng nhà ở';
        case 'numberHouseProvided':
            return 'Số lượng nhà ở được cấp GCN QSDD';
        case 'projectStatusName':
            return 'Trạng thái';
        case 'totalInvestment':
            return 'Tổng mức đầu tư';
        case 'projectTypeName':
            return 'Loại dự án';
        case 'businessStatusName':
            return 'Tình trạng doanh nghiệp';
        case 'constructionProgress':
            return 'Tiến độ công trình';
        case 'dateApproval':
            return 'Ngày quyết định phê duyệt';
        case 'establishConsultingUnit':
            return 'Đơn vị ra quyết định';
        case 'numberApproval':
            return 'Số quyết định phê duyệt';
        case 'planningName':
            return 'Quy hoạch';
        case 'constructionRealEstateTypeName':
            return 'Loại công trình';
        case 'numberFloor':
            return 'Số tầng';
        case 'numberHouse':
            return 'Số lượng căn hộ';
        case 'realEstateProjectName':
            return 'Dự án';
        case 'status':
            return 'Tình trạng hoạt động';
        case 'legalRepresentative':
            return 'Đại diện pháp nhân';
        case 'businessCode':
            return 'Mã doanh nghiệp';
        case '':
            return '';
        case '':
            return '';
        case '':
            return '';
        case '':
            return '';
        case '':
            return '';
        default:
            return '';
    }
}
const isBoolean = (value) => {
    return typeof value === 'boolean';
}

const isIsoDate = (str) => {
    const isoDatePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?(?:Z|[+-]\d{2}:\d{2})?$/;
    if (!isoDatePattern.test(str)) {
        return false;
    }

    const date = new Date(str);
    return !isNaN(date.getTime());
}

const aliasValue = (value) => {
    if (isIsoDate(value)) return dateformat(value, "dd-mm-yyyy");
    if (isBoolean(value) && value) return 'Đang hoạt động';
    if (isBoolean(value) && !value) return 'Đã giải thể';
    else return value;
}

export const convertProjectInfor = (input) => {
    if (!input) return
    return Object.keys(input).map(key => {
        return {
            label: aliasKey(key),
            value: aliasValue(input[key])
        };
    });
}