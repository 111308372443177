import React, { useEffect, useState } from "react";
import "./look-up-planning.scss";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import SearchByAddress from "./child-modules/search-address/search-address.view";
import PlanningByTypeView from "./child-modules/planning-type/planning-type.view";
import UrlCollect from "../../common/url-collect";
import SearchPlanningView from "./child-modules/search-planning-name/search-planning-name.view";
import * as statementActions from "../../redux/store/statement/statement.store";
import history from "../../common/history";
import * as mapHelper from "../../common/map";
import ShowNotification from "../../components/react-notifications/react-notifications";
import * as viVN from "../../languages/vi-VN.json";
import uongBiCoordinate from "../../common/uongbi_coordinate";
import { GetVn2000ToWgs84, GetLatLngPlace } from "../../redux/store/home-page/home-page.store";
import { AsyncPaginate } from "react-select-async-paginate";
import {
  NotificationMessageType,
  NotificationPosition,
} from "../../utils/configuration";
import { planningIdInfo } from "../../mock-data/planningIdMock.mock";
function LookUpPlanningView(props) {
  const { is_called_from_mobile } = props;
  
  const [planningType, setPlanningType] = useState([]);
  const [dataSelectBoxSearch, setDataSelectBoxSearch] = useState([]);
  const [selected, setSelected] = useState();
  const [show, setShow] = useState(true);
  let commentInput = null;

  useEffect(() => {
    getAllPlanningType();
  }, []);

  const getAllPlanningType = () => {
    statementActions
      .PlanningType()
      .then((res) => {
        if (res && res.content) {
          setPlanningType(res.content);
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  let PATHNAME =
    "?key=" + new URLSearchParams(props.location.search).get("key") + '&kindId=1';

  function createClassListLeftSelectMenu(pathName) {
    return PATHNAME === pathName ? "p-2 active-custom" : "p-2";
  }

  function handleSearchCoordinate(event) {
    event.preventDefault();
    let value = commentInput.value.trim();
    let coordinateList = value.split(",");
    if (coordinateList && coordinateList.length === 2) {
      let x = parseFloat(coordinateList[0]);
      let y = parseFloat(coordinateList[1]);

      GetVn2000ToWgs84(x, y).then((res) => {
        console.log(res)
        let isInside = false;
        props.listPolygon &&
          props.listPolygon.map((itemPolygon) => {
            if (mapHelper.isPointInPolygon(res[1], res[0], itemPolygon)) {
              isInside = true;
              return;
            }
            if (isInside) {
              history.push(
                `${!is_called_from_mobile ? UrlCollect.PlanningMapViewDetail : UrlCollect.PlanningMapViewDetailMobile}2?lng=${x}&lat=${y}&searchType=codinate`
              );
            } else {
              ShowNotification(
                viVN.Errors.CoordinateOutSidePolygon,
                NotificationMessageType.Warning,
                NotificationPosition.Center
              );
            }
          });

      },
        (err) => {
          ShowNotification(
            viVN.Errors.CoordinateOutSidePolygon,
            NotificationMessageType.Warning,
            NotificationPosition.Center
          );
        }
      )
    }
  }

  const handleSelectAddress = (address) => {
    GetLatLngPlace(address.label)
      .then((res) => res.result[0])
      .then((result) => result.location)
      .then((latLng) => {
        let isInside = false;
        props.listPolygon &&
          props.listPolygon.map((itemPolygon, index) => {
            if (
              mapHelper.isPointInPolygon(latLng.lat, latLng.lng, itemPolygon)
            ) {
              isInside = true;
              return;
            }
          });
        if (isInside) {
          history.push(
            `${!is_called_from_mobile ? UrlCollect.PlanningMapViewDetail : UrlCollect.PlanningMapViewDetailMobile}2?lat=${latLng.lat}&lng=${latLng.lng}&searchType=place`
          );
        } else {
          ShowNotification(
            viVN.Errors.CoordinateOutSidePolygon,
            NotificationMessageType.Warning,
            NotificationPosition.Center
          );
        }
      })
      .catch();
  };

  const [filteredSuggestions, setFilteredSuggestions] = useState([]);

  const Options = async (search, loadedOptions) => {
    if (search.length >= 4) {
      GetLatLngPlace(search)
        .then((res) => {
          setFilteredSuggestions(res && res.result && res.result.length > 0 ? res.result : [])
        })
        .catch()
    }

    const addressSuggestions = filteredSuggestions.map((item, index) => {
      return ({
        value: index,
        label: item.address
      })
    })
    return {
      options: addressSuggestions,
    }
  }

  const getPlanningTypeBasedOnKind = (kindId) => {
    switch (kindId) {
      case 2:
        return 8;
      case 3:
        return 14;
      case 4:
        return 13;
      default:
        break;
    }
  }

  function getLinkBasedOnKind(kindId) {
    switch (kindId) {
      case 2:
        return `${!is_called_from_mobile ? UrlCollect.ktqhsdd : UrlCollect.ktqhsdd_Mobile}${planningIdInfo.planningId_KHQHSDD}`;
      case 3:
        return `${!is_called_from_mobile ? UrlCollect.giao_thong : UrlCollect.giao_thong_Mobile}${planningIdInfo.planningId_GT}`;
      case 4:
        return `${!is_called_from_mobile ? UrlCollect.ProjectManagement : UrlCollect.ProjectManagementMobile}`;
      default:
        break;
    }
  }

  return (
    <div className="container look-up-planning-container">
      <div className="row">
        <div className="col-12 col-xl-3 left-menu">
          <SearchPlanningView
            dataSelectBoxSearch={dataSelectBoxSearch}
            setSelected={setSelected}
          />
          {show ? (
            <div className="row search-container no-gutters align-items-center">
              <div className="col-10">
                <AsyncPaginate
                  className="react-select-container  select-search"
                  //classNamePrefix="react-select"
                  cacheOptions
                  defaultMenuIsOpen={true}
                  defaultOptions
                  loadOptions={Options}
                  onChange={handleSelectAddress}
                  placeholder="Nhập địa điểm tra cứu"
                  noOptionsMessage={() => "Không có dữ liệu"}
                />
              </div>
              <div className="col-2 text-right">
                <button
                  className="bg-transparent border-0"
                  onClick={() => setShow(false)}
                >
                  <img
                    src={require("../../assets/icon/planning-name.svg")}
                    alt="Planning Name"
                  />
                </button>
              </div>
            </div>
          ) : (
            <div className="row search-container no-gutters align-items-center">
              <div className="col-10">
                <form onSubmit={handleSearchCoordinate}>
                  <input
                    type="text"
                    ref={(input) => {
                      commentInput = input;
                    }}
                    className="form-control"
                    placeholder="Nhập toạ độ VN2000 (Toạ độ X, Toạ độ Y)"
                  />
                </form>
              </div>
              <div className="col-2 text-right">
                <button
                  className="bg-transparent border-0"
                  onClick={() => setShow(true)}
                >
                  <img
                    src={require("../../assets/icon/place.svg")}
                    alt="Planning Name"
                  />
                </button>
              </div>
            </div>
          )}
          <div className="list-select-side-menu">
            <div className="menu_item">
              quy hoạch xây dựng
            </div>
            <a
              key={"SyntheticPlanning"}
              href={!is_called_from_mobile ? UrlCollect.SyntheticPlanning : UrlCollect.SyntheticPlanningMobile}
              className={createClassListLeftSelectMenu(
                !is_called_from_mobile ? UrlCollect.SyntheticPlanning : UrlCollect.SyntheticPlanningMobile
              )}
            >
              Tổng hợp quy hoạch
            </a>
            {planningType &&
              planningType.length > 0 &&
              planningType.map((item, index) => (
                <Link
                  key={index}
                  to={`${!is_called_from_mobile ? UrlCollect.PlanningMap : UrlCollect.PlanningMapMobile}?key=${item.id}&kindId=1`}
                  className={createClassListLeftSelectMenu("?key=" + item.id + '&kindId=1')}
                >
                  {item.name}
                </Link>
              ))}
            {
              props.planningKind.map((kind)=>(
                kind.id != '1' && (
                  <div className="title-container menu_item" >
                    <a href={`${getLinkBasedOnKind(kind.id)}`}>
                      {kind.name}
                    </a>
                  </div>
                )
              ))
            }
          </div>
        </div>
        <div className="col-md-9 col-12">
          <PlanningByTypeView
            params={props}
            setDataSelectBoxSearch={setDataSelectBoxSearch}
            selected={selected}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  listPlanningCategoriesAll: state.mapData.listPlanningCategoriesAll,
  openLayerController: state.openlayer.openLayerController,
  planningType: state.statement.planningType,
  planningKind: state.statement.planningKind,
  listPolygon: state.mapData.listPolygon,
  is_called_from_mobile: state.embeddedStatus.isEmbedded
});
export default connect(mapStateToProps)(LookUpPlanningView)
