import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";
import { showTabDetailProject } from "../../../modules/house-estate-project/helper/helper";

const service = new Service();

export const GetListHousingProject = (data) => {
    const params = new URLSearchParams();
    params.append("PageIndex", data.pageIndex);
    params.append("PageSize", data.pageSize);
    data.sorting && params.append("Sorting", data.sorting);
    data.keyword && params.append("ProjectName", data.keyword);

    return service
        .get(ApiUrl.GetListAll_HousingProject, params)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const GetDetailHousingProject = (id) => {
    return service
        .get(ApiUrl.GetDetail_HousingProject(id))
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const GetListRealEstateProject = (data) => {
    const params = new URLSearchParams();
    params.append("PageIndex", data.pageIndex);
    params.append("PageSize", data.pageSize);
    data.sorting && params.append("Sorting", data.sorting);
    data.keyword && params.append("ProjectName", data.keyword);

    return service
        .get(ApiUrl.GetListAll_RealEstateProject, params)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const GetDetailRealEstateProject = (id) => {
    return service
        .get(ApiUrl.GetDetail_RealEstateProject(id))
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const GetListConstructionRealEstate = (data) => {
    const params = new URLSearchParams();
    params.append("PageIndex", data.pageIndex);
    params.append("PageSize", data.pageSize);
    data.sorting && params.append("Sorting", data.sorting);
    data.keyword && params.append("ProjectName", data.keyword);

    return service
        .get(ApiUrl.GetListAll_ConstructionRealEstate, params)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const GetDetailConstructionRealEstate = (id) => {
    return service
        .get(ApiUrl.GetDetail_ConstructionRealEstate(id))
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const GetListRealEstateExchangeLayer = (data) => {
    const params = new URLSearchParams();
    params.append("PageIndex", data.pageIndex);
    params.append("PageSize", data.pageSize);
    data.sorting && params.append("Sorting", data.sorting);
    data.keyword && params.append("RealEstateExchangeName", data.keyword);

    return service
        .get(ApiUrl.GetListAll_RealEstateExchangeLayer, params)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const GetDetailRealEstateExchangeLayer = (id) => {
    return service
        .get(ApiUrl.GetDetail_RealEstateExchangeLayer(id))
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

const PROJECTSELECTED = "PROJECTSELECTED";
const DETAILPROJECTSELECTED = "DETAILPROJECTSELECTED";

const onChangeCurrentProjectSelect = (data) => ({
    type: PROJECTSELECTED,
    data: data,
});

const onChangeDetailProject = (data) => ({
    type: DETAILPROJECTSELECTED,
    data: data,
});


const initialState = {
    currentProjectSelect: {
        type: 0,
        show: false,
    },
    detailCurrentProjectSelect: {
        type: 0,
        data: {},
    }
};

export default function HousingEstateReducer(state = initialState, action) {
    switch (action.type) {
        case PROJECTSELECTED: {
            return { ...state, currentProjectSelect: action.data }
        }
        case DETAILPROJECTSELECTED: {
            return { ...state, detailCurrentProjectSelect: action.data }
        }
        default: return state
    }
}

export const setCurrentProjectSelect = (type, isShow) => {
    return (dispatch) => {
        dispatch(onChangeCurrentProjectSelect({
            type: type,
            show: isShow
        }));
    };
};

export const setDetailProjectSelect = (type, id) => {
    return (dispatch) => {
        switch (type) {
            case 1:
                GetDetailHousingProject(id).then(res => {
                    if (res && res.content) {
                        dispatch(onChangeDetailProject({
                            type: type,
                            data: res.content
                        }));
                        showTabDetailProject();
                    }
                    else {
                        dispatch(onChangeDetailProject({
                            type: 0,
                            data: {}
                        }));
                    }
                }).catch(err => {console.log(err)})
                break;
            case 2:
                GetDetailRealEstateProject(id).then(res => {
                    if (res && res.content) {
                        dispatch(onChangeDetailProject({
                            type: type,
                            data: res.content
                        }));
                        showTabDetailProject();
                    }
                    else {
                        dispatch(onChangeDetailProject({
                            type: 0,
                            data: {}
                        }));
                    }
                }).catch(err => {console.log(err)})
                break;
            case 3:
                GetDetailConstructionRealEstate(id).then(res => {
                    if (res && res.content) {
                        dispatch(onChangeDetailProject({
                            type: type,
                            data: res.content
                        }));
                        showTabDetailProject();
                    }
                    else {
                        dispatch(onChangeDetailProject({
                            type: 0,
                            data: {}
                        }));
                    }
                }).catch(err => {console.log(err)})
                break;
            case 4:
                GetDetailRealEstateExchangeLayer(id).then(res => {
                    if (res && res.content) {
                        dispatch(onChangeDetailProject({
                            type: type,
                            data: res.content
                        }));
                        showTabDetailProject();
                    }
                    else {
                        dispatch(onChangeDetailProject({
                            type: 0,
                            data: {}
                        }));
                    }
                }).catch(err => {console.log(err)})
                break;
            default:
                dispatch(onChangeDetailProject({
                    type: 0,
                    data: {}
                }));
        }
    };
};
