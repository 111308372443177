import React, { useState, useEffect, useRef } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as MapDataStore from "../../../../redux/store/map-data/map-data.store";
import { APIUrlDefault } from "../../../../utils/configuration";
import GaleryCarousel from "./garely-carousel"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "@material-ui/core";
import { getProjectTypeName, convertProjectInfor } from "../../helper/helper";

import ShowNotification from "../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../utils/configuration";
import * as viVN from "../../../../languages/vi-VN.json";
import { saveAs } from "file-saver";
import * as ApiConfig from "../../../../api/api-config";
import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function ProjectDetail(props) {
    const { projectDetailSelected, isMobile } = props;

    const settingSlider = {
        //dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    useEffect(() => {
        if (projectDetailSelected.data?.id) {
            // props.openLayerController?.placeSearch([108.000104, 13.990331])
            // if (
            //     props.openLayerController &&
            //     props.openLayerController.placeSearch
            // ) {
            //     props.openLayerController.placeSearch([108.000104, 13.990331]);
            // }
        }
    }, [projectDetailSelected])

    const savePreviewInfo = (previewLabel, imageSrc) => {
        const data = {
            previewLabel: previewLabel,
            imageSrc: imageSrc
        }
        props.savePreviewImage(data);
    }

    const getImageRow = (index, label, rowValue) => {
        return (
            <tr key={index}>
                <td colSpan="2">
                    <div className="image-title">{label}:</div>
                    <div
                        onClick={() => savePreviewInfo(label, rowValue)}
                        className="image-show"
                        data-toggle="modal"
                        data-target="#previewImage"
                    ><img src={rowValue} />
                    </div>
                </td>
            </tr>
        )
    }
    const getDataRow = (index, label, rowValue) => {
        return (
            <tr key={index}>
                <td className="row-label" width="50%" style={{ borderBottom: '1px solid #cdcdcd' }}>{label}</td>
                <td width="50%" style={{ borderBottom: '1px solid #cdcdcd' }}>{rowValue}</td>
            </tr>
        )
    }
    const getRowData = (data, index) => {
        // console.log(data);
        if (data.label == 'id') return;
        const rowLabel = data.label;
        const rowValue = data.value;
        const arrRow = rowLabel;
        let label = data.label;
        let labelPrefix = '';
        if (Array.isArray(arrRow) && arrRow.length == 2) {
            labelPrefix = arrRow[0];
            label = arrRow[1];
        }

        if (label && label == 'avatar') {
            return getImageRow(index, "Hình ảnh", APIUrlDefault + rowValue);
        } if (label && label == 'images') {
            return <GaleryCarousel index={index} label={"Thư viện ảnh"} rowValue={rowValue} />
        } else {
            return getDataRow(index, label, rowValue);
        }
    }

    return (
        <div className={`project_detail ${isMobile ? 'ismobile' : ''}`}>
            {projectDetailSelected.type ? (
                <>
                    <div className="header">
                        {/* <h3 className="title">
                            {getProjectTypeName(projectDetailSelected.type)}
                        </h3> */}
                        <h5 className="name">
                            {projectDetailSelected.data?.projectName ?? projectDetailSelected.data?.realEstateExchangeName}
                        </h5>
                    </div>
                    <div className="slide">
                        {projectDetailSelected.data?.galleryFiles && projectDetailSelected.data?.galleryFiles.length > 0 && (
                            <div className="slider_galary">
                                <Slider {...settingSlider}>
                                    {projectDetailSelected.data?.galleryFiles.map(
                                        (item, index) =>
                                            <div key={index}>
                                                <img src={ApiConfig.api + item.filePreview} alt={item.fileName} />
                                            </div>
                                    )}
                                </Slider>
                            </div>
                        )}
                    </div>
                    {projectDetailSelected.data?.legalDocuments
                        && projectDetailSelected.data?.legalDocuments.length > 0 && (
                            <div className="document mb-3">
                                Quyết định:
                                <div>
                                    {projectDetailSelected.data?.legalDocuments.map((item, index) => (
                                        <div key={index}>
                                            <a
                                                className="document_item cursor-pointer"
                                                onClick={() => {
                                                    if (item && item.filePreview) {
                                                        saveAs(
                                                            APIUrlDefault + item.filePreview,
                                                            item.fileName
                                                        )
                                                    } else {
                                                        ShowNotification(
                                                            viVN.Warning.NotFileDownload,
                                                            NotificationMessageType.Warning
                                                        );
                                                    }
                                                }}
                                            >
                                                <span className="mr-2">{item.fileName}</span>
                                                <FontAwesomeIcon
                                                    icon={faDownload}
                                                    color=""
                                                    className="mr-2"
                                                />
                                            </a>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    <div className="info-table">
                        {convertProjectInfor(projectDetailSelected.data).length > 0 && (
                            <table style={{ width: '100%' }}>
                                <tbody>
                                    {convertProjectInfor(projectDetailSelected.data).map((data, index) => {
                                        if (data.label && data.value) {
                                            return getRowData(data, index);
                                        }
                                    }
                                    )}
                                </tbody>
                            </table>
                        )}
                    </div>
                    {projectDetailSelected.data?.planningId ? (
                        <div className="mt-3">
                            <a href={`/ban-do-quy-hoach/${projectDetailSelected.data?.planningId}`} target="_blank" >
                                Xem bản đồ chi tiết dự án.
                            </a>
                        </div>
                    ): ""}
                </>
            ) : (
                <div className="text-center p-3">
                    Vui lòng chọn Dự án để xem thông tin!
                </div>
            )}

        </div>
    );

}

const mapStateToProps = (state) => ({
    openLayerController: state.openlayer.openLayerController,
    projectDetailSelected: state.currentProjectSelected.detailCurrentProjectSelect,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            savePreviewImage: MapDataStore.SavePreviewImage,
        }, dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetail);