import React, { useEffect, useState, Fragment, lazy, Suspense } from "react";
import "./general-planning-with-hooks.scss";
import OpenlayersMapView from "../openlayers-map/openlayers-map.view";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as MapDataStore from "../../redux/store/map-data/map-data.store";
import { GeneralPlanningViewProps, GeneralPlanningViewState, } from "../../models/general-planning-view/props-state.model";
import { isMobile } from 'react-device-detect';
import {
  ExtractListLayerArray,
  ExtractListLayerArrayFromArrMapData,
  ExtracLayerListGroup,
  ExtracLayerListGroupFromArrMapData,
} from "../../common/extract-information-from-map-data";
import { fixedDataGroupLayersGeneralWithDetail } from "../../mock-data/fixedDataGroupLayers.mock";
import { planningIdInfo } from "../../mock-data/planningIdMock.mock";
import { ToggleBottomProvider } from "../../components/mobile-bottom-toggle/context/ToggleBottomPanelContext";
import { AddContentBottomPanelProvider } from "./mobile/context/content-bottom-panel/ContentBottomPanel";
import { TopRightPanelProvider } from "../../components/mobile-bottom-toggle/mobile-bottom-toggle-content/TopRightPanelContext";
import PreviewImgModal from "../../components/left-menu-component/preview-img-modal/preview-img-modal";
import { SetDisplayInfomationPopup, SetDisplayMaptoolPanel } from "../../redux/store/open-layer/map-tools-panels.store";

const ListBaseMap = [
  {
    base_map_setting_id: 0,
    id: 1,
    map_setting_id: 1,
    url: "https://mt0.google.com/vt/lyrs=m&hl=vi&x={x}&y={y}&z={z}",
    view_default: true,
    z_index: 0,
    name: "Bản đồ nền đường phố",
    baseMapSettingModel: {
      id: 0,
      layer_type: "",
      status: true,
      url: "",
    },
  },
  {
    base_map_setting_id: 0,
    id: 1,
    map_setting_id: 1,
    url: "https://mt0.google.com/vt/lyrs=y&hl=vi&x={x}&y={y}&z={z}",
    view_default: true,
    z_index: 0,
    name: "Bản đồ vệ tinh",
    baseMapSettingModel: {
      id: 0,
      layer_type: "",
      status: true,
      url: "",
    },
  },
  {
    base_map_setting_id: 0,
    id: 1,
    map_setting_id: 1,
    url: "",
    view_default: true,
    z_index: 0,
    name: "Không nền",
    baseMapSettingModel: {
      id: 0,
      layer_type: "",
      status: true,
      url: "",
    },
  },
];

//Desktop
const LeftMenuView = lazy(() => import("../general-planning-with-detail/left-menu/left-menu.view"));
const TopRightPanelView = lazy(() => import("../planning-map-view/top-right-panel/top-right-panel.view"));
const GuideUseMap = lazy(() => import("../general-planning-with-detail/guide-use-map/guide-use-map.view"));

// Mobile
const TopRightPanelMobile = lazy(() => import("./mobile/top-right-panel-mobile/top-right-panel-mobile"));
const BottomToggleMobile = lazy(()=> import("../../components/mobile-bottom-toggle/bottom-toggle"));
const BottomPanelMobile = lazy(()=> import("./mobile/bottom-panel-mobile/bottom-panel-mobile"));
const GuideUseModalMobile = lazy(()=> import("./mobile/guide-use-modal/guide-use-modal"));


function GeneralPlanningViewWithHooks(props) {
  const [isLeftNavbarHide, setIsLeftNavbarHide] = useState(false);
  const [isRightNavbarHide, setIsRightNavbarHide] = useState(false);
  const [modalHeightStyle, setModalHeightStyle] = useState(0);
  const [isDoneConstructMap, setIsDoneConstructMap] = useState(false);
  const [fixedDataGroupLayersState, setFixedDataGroupLayersState] = useState(fixedDataGroupLayersGeneralWithDetail);
  const [isShowFilterInformationPopup, setIsShowFilterInformationPopup] = useState(false);
  const [isDisplaySearchResult, setIsDisplaySearchResult] = useState(false);
  const [displaySearchLocationBar, setDisplaySearchLocationBar] = useState(false);
  const [coordinate, setCoordinate] = useState('');

  const [listLayers, setListLayers] = useState([]);
  const [listLayers_KHQHSDD, setListLayers_KHQHSDD] = useState([]);
  const [listLayers_GT, setListLayers_GT] = useState([]);
  const [listLayers_DADTXD, setListLayers_DADTXD] = useState([]);

  const [layerGroupsPlace, setLayerGroupsPlace] = useState([]);
  const [layerGroups_KHQHSDD, setLayerGroups_KHQHSDD] = useState([]);
  const [layerGroups_GT, setLayerGroups_GT] = useState([]);
  const [layerGroups_DADTXD, setLayerGroups_DADTXD] = useState([]);

  useEffect(()=>{
    const leftMenuElement = document.getElementsByClassName(
      "left-custom-navbar"
    )[0];

    if(!leftMenuElement) return;
    
    if (!isLeftNavbarHide) {
      leftMenuElement.classList.add("hide-left-navbar");
    } else
      leftMenuElement.classList.remove("hide-left-navbar");
    },[isLeftNavbarHide])

  const handleShowHidePlanningMenu = () => {
    const leftMenuElement = document.getElementsByClassName(
        "left-custom-navbar"
    )[0];
    const leftMenuContainer = document.getElementsByClassName(
      "custom-container-left-menu"
    )[0];

    if(!leftMenuContainer || !leftMenuElement) return;

    leftMenuElement.style.left = isLeftNavbarHide ? '-340px' : '0';
    leftMenuContainer.style.maxWidth = isLeftNavbarHide ? "unset" : '29vw';
  }

  useEffect(()=>{
    handleShowHidePlanningMenu();
  },[isLeftNavbarHide])

  useEffect(() => {
    setModalHeightStyle(window.innerHeight - 100);
    // props.GetAllPlanningBoundaries();

    props.GetArrMapDetail([planningIdInfo.planningId_POINT_MAP]);
    props.GetArrMapDetail([planningIdInfo.planningId_HOME_MAP]);

    props.GetArrMapDetail([planningIdInfo.planningId_KHQHSDD], "KHQHSDD");
    props.GetArrMapDetail([planningIdInfo.planningId_GT], "GT");
    props.GetArrMapDetail([planningIdInfo.planningId_DADTXD], "DADTXD");

    if(isMobile){
      handleShowHidePlanningMenu();
    }

    return () => {
      props.SetDisplayMaptoolPanel();
      props.SetDisplayInfomationPopup();
      props.ResetMapData();
    };
  }, [])

  useEffect(() => {
    if (isDoneConstructMap && props.arrMapData_KHQHSDD && props.arrMapData_KHQHSDD.length > 0) {
      console.log("props.arrMapData_KHQHSDD changed", props.arrMapData_KHQHSDD);
      setListLayers_KHQHSDD(ExtractListLayerArrayFromArrMapData(props.arrMapData_KHQHSDD));
      setLayerGroups_KHQHSDD(ExtracLayerListGroupFromArrMapData(props.arrMapData_KHQHSDD));
    }
    if (isDoneConstructMap && props.arrMapData_GT && props.arrMapData_GT.length > 0) {
      console.log("props.arrMapData_GT changed", props.arrMapData_GT);
      setListLayers_GT(ExtractListLayerArrayFromArrMapData(props.arrMapData_GT));
      setLayerGroups_GT(ExtracLayerListGroupFromArrMapData(props.arrMapData_GT));
    }
    if (isDoneConstructMap && props.arrMapData_DADTXD && props.arrMapData_DADTXD.length > 0) {
      console.log("props.arrMapData_DADTXD changed", props.arrMapData_DADTXD);
      setListLayers_DADTXD(ExtractListLayerArrayFromArrMapData(props.arrMapData_DADTXD));
      setLayerGroups_DADTXD(ExtracLayerListGroupFromArrMapData(props.arrMapData_DADTXD));
    }
  }, [props.arrMapData_KHQHSDD, props.arrMapData_GT, props.arrMapData_DADTXD, isDoneConstructMap])

  useEffect(() => {
    if (isDoneConstructMap &&
      listLayers_KHQHSDD.length > 0 &&
      listLayers_GT.length > 0 &&
      listLayers_DADTXD.length > 0 &&
      props.arrMapData &&
      props.arrMapData.length > 0
    ) {
      setListLayers([
        ...ExtractListLayerArrayFromArrMapData(props.arrMapData),
        ...listLayers_KHQHSDD,
        ...listLayers_GT,
        ...listLayers_DADTXD,
      ]);
      setLayerGroupsPlace(ExtracLayerListGroupFromArrMapData(props.arrMapData));
      props.openLayerController.addLayerManuallyFIXED(fixedDataGroupLayersState);
    }
  }, [
    isDoneConstructMap,
    listLayers_DADTXD,
    listLayers_GT,
    listLayers_KHQHSDD,
    props.arrMapData
  ])


  return (
    <ToggleBottomProvider>
      <TopRightPanelProvider>
          <AddContentBottomPanelProvider>
            <div className="general-planning-map-view-container position-relative">
              {
                props.isTabletOrMobile && 
                <Suspense fallback={<div>Page is Loading, please wait...</div>}>
                  {/* Top right panel*/}
                  <TopRightPanelMobile
                    baseMapList={ListBaseMap}
                  />

                  <BottomToggleMobile/>

                  <BottomPanelMobile />

                  <GuideUseModalMobile
                    modalHeightStyle={modalHeightStyle}
                  />
                </Suspense>
              }

              {
                props.isDesktopOrLaptop && 
                <Suspense fallback={<div>Page is Loading, please wait...</div>}>
                  <TopRightPanelView
                      isHiddenMapTools={true}
                      baseMapList={ListBaseMap}
                      isHiddenSearch={true}
                      isHiddenLayer={true}
                      isHiddenInfor={true}
                  />

                  <div className="left-custom-navbar position-absolute h-100">
                    <LeftMenuView
                      planningId={5}
                      listGroupLayer={layerGroupsPlace}
                      isLeftNavbarHide={isLeftNavbarHide}
                      toggleStateIsLeftNavbarHide={() =>
                        setIsLeftNavbarHide(
                          (currentIsLeftNavbarHide) => {return !currentIsLeftNavbarHide}
                        )
                      }
                    />
                  </div>

                  <GuideUseMap modalHeightStyle = {modalHeightStyle}/>
                </Suspense>
              }
              {/* Open layer view port */}
              <OpenlayersMapView
                projection={"EPSG:4326"}
                isGeneralPlanningWithDetail={true}
                coordinate={props.location}
                listLayer={listLayers}
                setIsDoneConstructMap={setIsDoneConstructMap}

                //props for assissive chip in map view
                layerGroupsPlace={layerGroupsPlace}
                layerGroups_KHQHSDD={layerGroups_KHQHSDD}
                layerGroups_GT={layerGroups_GT}
                layerGroups_DADTXD={layerGroups_DADTXD}
                fixedDataGroupLayers={fixedDataGroupLayersState}
                setFixedDataGroupLayers={setFixedDataGroupLayersState}
              />

              <PreviewImgModal />
            </div>
        </AddContentBottomPanelProvider>
      </TopRightPanelProvider>
    </ToggleBottomProvider>
  );
}

const mapStateToProps = (state) => ({
  hasLoadedMapData: state.mapData.hasLoadingData,
  mapData: state.mapData.mapData,
  arrMapData: state.mapData.arrMapData,
  arrMapData_KHQHSDD: state.mapData.arrMapDataKHQHSDD,
  arrMapData_GT: state.mapData.arrMapDataGT,
  arrMapData_DADTXD: state.mapData.arrMapDataDADTXD,
  planningRelationShips: state.mapData.planningRelationShips,
  listPlanning: state.mapData.listPlanning,
  listPlanningCategoriesAll: state.mapData.listPlanningCategoriesAll,
  openLayerController: state.openlayer.openLayerController,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetMapDetail: MapDataStore.GetMapDetailByPlanningId,
      ResetMapData: MapDataStore.ResetMapData,
      GetArrMapDetail: MapDataStore.GetArrMapDetailByPlanningId,
      GetAllPlanningCategoriesType: MapDataStore.GetAllPlanningCategoriesType,
      GetAllPlanning: MapDataStore.GetAllPlanning,
      GetAllPlanningBoundaries: MapDataStore.GetAllPlanningBoundaries,
      SaveHeaderForTable: MapDataStore.SaveHeaderForTable,
      SetDisplayMaptoolPanel: SetDisplayMaptoolPanel,
      SetDisplayInfomationPopup: SetDisplayInfomationPopup,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneralPlanningViewWithHooks);