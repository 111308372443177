/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTable } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";

import * as consultantCommunityActions from "../../../redux/store/consultant-community/consultant-community.store";
import * as appActions from "../../../core/app.store";

import Pagination from "../../../components/pagination/pagination.view";

import ShowNotification from "../../../components/react-notifications/react-notifications";
import {
  NotificationMessageType,
  DomainAdminSide,
  NotificationPosition,
} from "../../../utils/configuration";
import * as viVN from "../../../languages/vi-VN.json";

import "./summary-ideas.scss";

function SummaryIdeasView(props) {
  const { showLoading } = props;

  const [resultModel, setResultModel] = useState([]);
  const [resultItem, setResultItem] = useState("");
  const [questions, setQuestions] = useState("");
  const currentPageIndexResult = 1;
  const currentPageSizeResult = 10;
  const [totalItemCountResult, setTotalItemCountResult] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const [show, setShow] = useState(false);

  useEffect(() => {
    let search = new URLSearchParams(props.location.search).get("search");
    onGetListExpiredForm({
      pageIndex: currentPageIndexResult,
      pageSize: currentPageSizeResult,
      consultantCommunityId: (search && Number(search)) || null,
    });
  }, []);

  const onGetListExpiredForm = (data) => {
    showLoading(true);
    consultantCommunityActions.GetListExpiredForm(data).then(
      (res) => {
        setResultModel(
          res &&
            res.content &&
            res.content.items &&
            res.content.items.length > 0
            ? res.content.items
            : []
        );
        setTotalItemCountResult(
          res && res.content && res.content.totalItemCount
            ? res.content.totalItemCount
            : 0
        );
        setPageCount(
          res && res.content && res.content.pageCount
            ? res.content.pageCount
            : 0
        );

        showLoading(false);
      },
      (err) => {
        showLoading(false);
      }
    );
  };

  const GetReportFeedback = (id) => {
    showLoading(true);
    consultantCommunityActions.GetReportFeedback(id).then(
      (res) => {
        if (res && res.content) {
          setQuestions(res.content);
          setShow(true);
        }
        showLoading(false);
      },
      (err) => {
        showLoading(false);
        ShowNotification(
          viVN.Errors[(err && err.errorType) || "UnableHandleException"],
          NotificationMessageType.Error
        );
      }
    );
  };
  
  const onShowFeedBack = (item) => {
    if (item.numberResult > 0) {
      GetReportFeedback(item.id);
      setResultItem(item);
    } else {
      ShowNotification(
        "Chưa có ý kiến nào",
        NotificationMessageType.Warning,
        NotificationPosition.Center
      );
    }
  };

  return (
    <div className="mt-5">
      <div className="container">
        <div className="summary-ideas p-3 border">
          {props.location &&
          props.location.search &&
          resultModel &&
          resultModel.length > 0 ? (
            <h5 className="title">{resultModel[0].planningName}</h5>
          ) : (
            <h5 className="title">Tổng hợp ý kiến Quy hoạch</h5>
          )}

          <h6 className="result mt-4">
            Có <span>{totalItemCountResult}</span> kết quả
          </h6>
          <div className="table-responsive mt-4">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">STT</th>
                  <th scope="col">Tên</th>
                  <th scope="col" className="text-nowrap">
                    Trạng thái
                  </th>
                  <th scope="col" className="text-nowrap">
                    Thời gian bắt đầu
                  </th>
                  <th scope="col" className="text-nowrap">
                    Thời gian kết thúc
                  </th>
                  <th scope="col" className="text-nowrap">
                    Số phiếu
                  </th>
                  <th scope="col" className="text-nowrap">
                    Chi tiết
                  </th>
                </tr>
              </thead>
              <tbody>
                {resultModel && resultModel.length > 0 ? (
                  resultModel.map((item, index) => (
                    <tr key={index}>
                      <td className="text-center">{index + 1}</td>
                      <td>
                        <b>{item.title}</b>
                        <br />
                        {!props.location.search ? item.planningName : ""}
                      </td>
                      <td>
                        {!item.isExpired ? "Đang xin ý kiến" : "Kết thúc"}
                      </td>
                      <td>
                        {moment(new Date(item.startTime)).format("DD/MM/YYYY")}
                      </td>
                      <td>
                        {moment(new Date(item.endTime)).format("DD/MM/YYYY")}
                      </td>
                      <td>{item.numberResult}</td>
                      <td className="text-center">
                        <FontAwesomeIcon
                          icon={faTable}
                          className="icon-table"
                          onClick={() => onShowFeedBack(item)}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7} className="text-center">
                      Không có dữ liệu
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {resultModel && resultModel.length > 0 && pageCount > 1 && (
          <div className="mt-3">
            <Pagination
              totalItems={totalItemCountResult}
              currentIndex={currentPageIndexResult}
              pageSize={currentPageSizeResult}
              onClick={(pageIndex) => {
                onGetListExpiredForm({
                  pageIndex: pageIndex,
                  pageSize: currentPageSizeResult,
                });
              }}
            />
          </div>
        )}
      </div>
      {resultItem && questions && show && (
        <Modal
          show={show}
          onHide={setShow}
          animation={true}
          size="xl"
          className="summary-ideas-detail"
          centered
          navbarNav
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h5 className="modal-title pl-3">
                Kết quả Xin ý kiến về {resultItem.title} -{" "}
                {resultItem.planningName}
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <table className="table table-responsive">
              <tbody>
                <tr>
                  <td className="border-top-0">
                    <b>Tổng số ý kiến:</b> {resultItem.numberResult}
                  </td>
                  {/* <td className="border-top-0">
                    <a
                      target="_blank"
                      href={`${DomainAdminSide}/quy-trinh-cong-bo-quy-hoach?showConsult=true&planningId=${resultItem.planningId}&planningName=${resultItem.planningName}`}
                      className="float-right"
                    >
                      Chi tiết
                    </a>
                  </td> */}
                </tr>
                {questions &&
                  questions.questions &&
                  questions.questions.length > 0 &&
                  questions.questions.map((itemQuestion, indexQuestion) => (
                    <tr key={`questions${indexQuestion}`}>
                      <td colSpan={2}>
                        <b>{`${indexQuestion + 1}. ${itemQuestion.title}`}</b>
                        <ul className="mb-0">
                          {itemQuestion.answers &&
                            itemQuestion.answers.length > 0 &&
                            itemQuestion.answers.map(
                              (itemAnswer, indexAnswer) => (
                                <li
                                  key={`questions${indexQuestion}answer${indexAnswer}`}
                                >
                                  <b>{itemAnswer.title}:</b> {itemAnswer.count}
                                </li>
                              )
                            )}
                          <li>
                            <b>Ý kiến khác:</b>
                            <br />
                            {itemQuestion.additions &&
                              itemQuestion.additions.length > 0 &&
                              itemQuestion.additions.map(
                                (itemAddition, indexAddition) =>
                                  itemAddition && (
                                    <p
                                      key={`questions${indexQuestion}addition${indexAddition}`}
                                      className="mb-0"
                                    >
                                      - {itemAddition}
                                    </p>
                                  )
                              )}
                          </li>
                        </ul>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(SummaryIdeasView);
