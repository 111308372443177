import React, { useEffect } from "react";
import Pagination from '@material-ui/lab/Pagination';

const FooterPagination = (props) => {
    const { totalPage, currentPage, totalItemCount, pageLimit = 10, setPageIndex } = props;

    const handleChangePage = (event, value) => {
        setPageIndex(value);
    };
    return (
        <div className='div-pagination'>
            {totalItemCount && (<div class=""><div>Tổng số bản ghi : <b>{totalItemCount}</b></div></div>)}
            <div className='footer_right'>

                <Pagination
                    showFirstButton
                    showLastButton
                    page={currentPage}
                    count={totalPage}
                    siblingCount={1}
                    boundaryCount={1}
                    color='primary'
                    onChange={handleChangePage}
                />
            </div>
        </div>
    );
};

export default FooterPagination;
