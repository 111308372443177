import React, { useEffect, useState } from 'react';
import * as projectAction from '../../../redux/store/housing-estate/housing-estate.store'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActions from "../../../core/app.store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import ListTable from './list-table';
import { getProjectTypeName } from '../helper/helper';
import { TextField } from '@material-ui/core';
import './list-footer.scss';

const ListFooter = (props) => {
    const { isMobile, isLeftNavbarHide, showLoading } = props;

    const pageSize = 10;
    const [pageIndex, setPageIndex] = useState(1);
    const [keyword, setKeyword] = useState('')
    const [listData, setListData] = useState([]);
    const [pagination, setPagination] = useState({});
    const [show, setShow] = useState(false);

    useEffect(() => {
        setPageIndex(1);
        setKeyword('');
    }, [props.currentSelected])

    useEffect(() => {
        setShow(props.currentSelected.show)
        if (!props.currentSelected.show) {
            setListData([])
            return
        }
        let params = {
            pageSize: pageSize,
            pageIndex: pageIndex,
        }
        getDataModel(params)
    }, [props.currentSelected])

    useEffect(() => {
        if (!props.currentSelected.show) {
            setListData([])
            return
        }
        let params = {
            pageSize: pageSize,
            pageIndex: pageIndex,
            keyword: keyword
        }
        getDataModel(params)
    }, [pageIndex])

    const getDataModel = async (params) => {
        showLoading(true)
        try {
            const { content } = await getFunction(props.currentSelected.type, params);
            if (content) {
                const { items, pageCount, pageIndex, pageSize, totalItemCount } = content || {};
                setListData(items);
                setPagination({ ...pagination, pageCount, pageIndex, pageSize, totalItemCount });
            } else {
                setListData([]);
            }
            showLoading(false)
        }
        catch (e) {
            setListData([]);
            showLoading(false)
        }
    }

    const getFunction = (type, params) => {
        switch (type) {
            case 1:
                return projectAction.GetListHousingProject(params);
            case 2:
                return projectAction.GetListRealEstateProject(params);
            case 3:
                return projectAction.GetListConstructionRealEstate(params);
            case 4:
                return projectAction.GetListRealEstateExchangeLayer(params);
            default:
                return null;
        }

    }

    const getDetailAction = (id) => {
        props.setDetailProjectSelect(props.currentSelected.type, id);
    }

    const handleChangeName = (event) => {
        setKeyword(event.target.value)
    }

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            let params = {
                pageSize: pageSize,
                pageIndex: pageIndex,
                keyword: keyword,
            }
            getDataModel(params)
        }
    }

    if (!isMobile) return (
        <div
            id="list_footer"
            className={`list_footer ${isMobile ? 'ismobile' : ''} ${isLeftNavbarHide ? 'full_width' : ''} ${!show ? 'hide' : ''}`}
        >
            <div className='footer_header'>
                <div className="title">
                    {'Danh sách ' + getProjectTypeName(props.currentSelected.type)}
                </div>
                <div className="footer_filter">
                    <TextField
                        variant="outlined"
                        name="keywork"
                        type="text"
                        className="form-control"
                        value={keyword}
                        onChange={handleChangeName}
                        onKeyPress={handleKeyPress}
                        placeholder='Nhập từ khóa'
                    />
                    <FontAwesomeIcon
                        icon={faSearch}
                        className="icon-search dropdown-toggle"
                    />
                </div>
            </div>
            <div className='list_footer_content'>
                <ListTable
                    tableType={props.currentSelected.type}
                    data={listData}
                    pagination={pagination}
                    pageIndex={pageIndex}
                    setPageIndex={setPageIndex}
                    getDetailAction={getDetailAction}
                />
                {/* <div className='row'>
                    {listData && listData.length > 0 && listData.map(item => (
                        <div key={item.id} className='col-12 project_item'>
                            <FontAwesomeIcon icon={faChevronRight} className="icon-link_" />
                            <div className='project_title'>
                                {item.projectName}
                            </div>
                        </div>
                    ))}
                </div> */}
            </div>
        </div>
    )

    else return (
        <div
            id="list_footer"
            className={`list_footer_mobile`}
        >
            {props.currentSelected.show ? (
                <>
                    <div className='footer_header'>
                        <div className="title">
                            {getProjectTypeName(props.currentSelected.type)}
                        </div>
                        {/* <div className="footer_filter">
                    <TextField
                        variant="outlined"
                        name="keywork"
                        type="text"
                        className="form-control"
                        value={keyword}
                        onChange={handleChangeName}
                        onKeyPress={handleKeyPress}
                        placeholder='Nhập từ khóa'
                    />
                    <FontAwesomeIcon
                        icon={faSearch}
                        className="icon-search dropdown-toggle"
                    />
                </div> */}
                    </div>
                    <div className='list_footer_content'>
                        <ListTable
                            tableType={props.currentSelected.type}
                            data={listData}
                            pagination={pagination}
                            pageIndex={pageIndex}
                            setPageIndex={setPageIndex}
                            getDetailAction={getDetailAction}
                            isMobile={isMobile}
                        />
                    </div>
                </>
            ) : (
                <div className='m-3 text-center'>Vui lòng chọn Danh dách</div>
            )}
        </div>
    )
}

const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
    currentSelected: state.currentProjectSelected.currentProjectSelect,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
            setCurrentProjectSelect: projectAction.setCurrentProjectSelect,
            setDetailProjectSelect: projectAction.setDetailProjectSelect,
        },
        dispatch
    );

export default (connect(mapStateToProps, mapDispatchToProps)(ListFooter));
