import React, { Suspense, lazy } from "react";
import "./planning-map-view.scss"
import OpenlayersMapView from "../openlayers-map/openlayers-map.view";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as MapDataStore from "../../redux/store/map-data/map-data.store";
import { SetDisplayMaptoolPanel, SetDisplayInfomationPopup } from "../../redux/store/open-layer/map-tools-panels.store";
import * as MapToolDataStore from "../../redux/store/map-tool/map-tool.store";
import PlanningMapViewModel from "../../models/planning-map-view/planning-map-view.model";
import {
  ExtractSelectedBasemap,
  ExtractCenterArray,
  ExtractExtentArray,
  ExtractListLayerArray,
  ExtracLayerListGroup,
  ExtractBasemapList,
  ExtracLayerListGroupFromArrMapData,
  ExtractListLayerArrayFromArrMapData,
} from "../../common/extract-information-from-map-data";
import { ToggleBottomProvider } from "../../components/mobile-bottom-toggle/context/ToggleBottomPanelContext";
// import { AddContentBottomPanelProvider } from "./mobile/context/content-bottom-panel/ContentBottomPanel";
// import { IsHideFilterBoxProvider } from "./mobile/context/is-hide-filter-box/IsHideFilterBox";
import { planningIdInfo } from "../../mock-data/planningIdMock.mock";
import { fixedDataGroupLayersPlanningView } from "../../mock-data/fixedDataGroupLayers.mock";
import { TopRightPanelProvider } from "../../components/mobile-bottom-toggle/mobile-bottom-toggle-content/TopRightPanelContext";
import { Link } from "react-router-dom";
import UrlCollect from "../../common/url-collect";
import PreviewImgModal from "../../components/left-menu-component/preview-img-modal/preview-img-modal";
import ListFooter from "./list-footer/list-footer.view";
import { isMobile } from "react-device-detect";
import MenuMobile from "./menu-mobile/menu-mobile";
//Desktop
const RenderFilterInformationPopup = lazy(() => import("./filter-infomation/filter-infomation.view.jsx"));
const LeftMenuView = lazy(() => import("./left-menu/left-menu.view"));
const MapToolsPanel = lazy(() => import("./map-tools-panel/map-tools-panel.view.jsx"));
const TopRightPanelView = lazy(() => import("../planning-map-view/top-right-panel/top-right-panel.view"));
const GuideUseMap = lazy(() => import("./guide-use-map/guide-use-map.view"));

// Mobile
// const RenderFilterInformationPopupMobile = lazy(() => import("./mobile/filter-infomation/filter-infomation.view.jsx"));
// const TopRightPanelMobile = lazy(() => import("./mobile/top-right-panel-mobile/top-right-panel-mobile"));
// const BottomToggleMobile = lazy(() => import("../../components/mobile-bottom-toggle/bottom-toggle"));
// const BottomPanelMobile = lazy(() => import("./mobile/bottom-panel-mobile/bottom-panel-mobile"));
// const GuideUseModalMobile = lazy(() => import("./mobile/guide-use-modal/guide-use-modal"));

class HousingAndEstateMapView extends React.Component<
  PlanningMapViewModel.PlanningMapViewProps,
  PlanningMapViewModel.PlanningMapViewState
> {
  constructor(props: PlanningMapViewModel.PlanningMapViewProps) {
    super(props);
    this.state = {
      mapPlanning: 44,
      isLeftNavbarHide: false,
      modalHeightStyle: 0,
      isShowMapToolsPanel: false,
      isShowFilterInfomationPopup: false,
      displaySearchLocationBar: false,
      planningModel: null,
      coordinate: "",
      isDoneConstructMap: false,

      fixedDataGroupLayersState: fixedDataGroupLayersPlanningView,
      layerGroups: [],
      layerGroups_KHQHSDD: [],
      layerGroups_GT: [],
      layerGroups_DADTXD: [],

      listLayers: [],
      listLayers_KHQHSDD: [],
      listLayers_GT: [],
      listLayers_DADTXD: [],
    };
    this.openFilterInfomationPopup = this.openFilterInfomationPopup.bind(this);
    this.closeFilterInfomationPopup = this.closeFilterInfomationPopup.bind(this);
  }

  setListLayers_KHQHSDD(data: any) {
    this.setState({
      listLayers_KHQHSDD: data
    })
  }

  setListLayers_GT(data: any) {
    this.setState({
      listLayers_GT: data
    })
  }

  setListLayers_DADTXD(data: any) {
    this.setState({
      listLayers_DADTXD: data
    })
  }

  setLayerGroups_KHQHSDD(data: any) {
    this.setState({
      layerGroups_KHQHSDD: data
    })
  }

  setLayerGroups_GT(data: any) {
    this.setState({
      layerGroups_GT: data
    })
  }

  setLayerGroups_DADTXD(data: any) {
    this.setState({
      layerGroups_DADTXD: data
    })
  }

  setListLayers(data: any) {
    this.setState({
      listLayers: data
    })
  }

  setFixedDataGroupLayersState(data: any) {
    this.setState({
      fixedDataGroupLayersState: data
    })
  }

  setIsDoneConstructMap(data: boolean) {
    this.setState({
      isDoneConstructMap: data
    })
  }

  componentDidMount() {
    this.setState({
      modalHeightStyle: window.innerHeight - 100,
    });
    this.props.GetMapDetail(this.state.mapPlanning);
    this.props.GetListPlanningRelationByPlanningId(this.state.mapPlanning);
    this.props.SetDisplayMaptoolPanel(false);
    this.props.SetDisplayInfomationPopup(false);
    //point places
    // this.props.GetArrMapDetail([planningIdInfo.planningId_POINT_MAP]);

    // if (this.state.mapPlanning == planningIdInfo.planningId_KHQHSDD) {
    //   this.props.GetArrMapDetail([planningIdInfo.planningId_DADTXD], "DADTXD");
    //   this.props.GetArrMapDetail([planningIdInfo.planningId_GT], "GT");
    // } else if (this.state.mapPlanning == planningIdInfo.planningId_DADTXD) {
    //   this.props.GetArrMapDetail([planningIdInfo.planningId_KHQHSDD], "KHQHSDD");
    //   this.props.GetArrMapDetail([planningIdInfo.planningId_GT], "GT");
    // } else if (this.state.mapPlanning == planningIdInfo.planningId_GT) {
    //   this.props.GetArrMapDetail([planningIdInfo.planningId_DADTXD], "DADTXD");
    //   this.props.GetArrMapDetail([planningIdInfo.planningId_KHQHSDD], "KHQHSDD");
    // } else {
    //   this.props.GetArrMapDetail([planningIdInfo.planningId_DADTXD], "DADTXD");
    //   this.props.GetArrMapDetail([planningIdInfo.planningId_KHQHSDD], "KHQHSDD");
    //   this.props.GetArrMapDetail([planningIdInfo.planningId_GT], "GT");
    // }
    // this.setFixedDataGroupLayersState(fixedDataGroupLayersPlanningView);
  }

  openFilterInfomationPopup() {
    this.setState({
      isShowFilterInfomationPopup: true,
    });
  }

  closeFilterInfomationPopup() {
    this.setState({
      isShowFilterInfomationPopup: false,
    });
  }

  setPlanningMap = (_planningModel: any) => {
    this.setState({ planningModel: _planningModel });
  };

  componentDidUpdate(prevProps: Readonly<PlanningMapViewModel.PlanningMapViewProps>, prevState: Readonly<PlanningMapViewModel.PlanningMapViewState>, snapshot?: any): void {
    if (this.props.mapData != prevProps.mapData) {
      this.props.SetDataToggleLayer(ExtracLayerListGroup(this.props.mapData));
    }

    if ((this.props.arrMapData !== prevProps.arrMapData)) {
      this.setState({
        layerGroups: ExtracLayerListGroupFromArrMapData(this.props.arrMapData)
      })
    }

    if ((this.props.arrMapData_KHQHSDD !== prevProps.arrMapData_KHQHSDD) ||
      (this.props.arrMapData_GT !== prevProps.arrMapData_GT) ||
      (this.props.arrMapData_DADTXD !== prevProps.arrMapData_DADTXD) ||
      (this.state.isDoneConstructMap !== prevState.isDoneConstructMap)
    ) {
      if (this.state.isDoneConstructMap && this.props.arrMapData_KHQHSDD && this.props.arrMapData_KHQHSDD.length > 0) {
        console.log("this.props.arrMapData_KHQHSDD changed", this.props.arrMapData_KHQHSDD);
        this.setListLayers_KHQHSDD(ExtractListLayerArrayFromArrMapData(this.props.arrMapData_KHQHSDD));
        this.setLayerGroups_KHQHSDD(ExtracLayerListGroupFromArrMapData(this.props.arrMapData_KHQHSDD));
      }
      if (this.state.isDoneConstructMap && this.props.arrMapData_GT && this.props.arrMapData_GT.length > 0) {
        console.log("this.props.arrMapData_GT changed", this.props.arrMapData_GT);
        this.setListLayers_GT(ExtractListLayerArrayFromArrMapData(this.props.arrMapData_GT));
        this.setLayerGroups_GT(ExtracLayerListGroupFromArrMapData(this.props.arrMapData_GT));
      }
      if (this.state.isDoneConstructMap && this.props.arrMapData_DADTXD && this.props.arrMapData_DADTXD.length > 0) {
        console.log("this.props.arrMapData_DADTXD changed", this.props.arrMapData_DADTXD);
        this.setListLayers_DADTXD(ExtractListLayerArrayFromArrMapData(this.props.arrMapData_DADTXD));
        this.setLayerGroups_DADTXD(ExtracLayerListGroupFromArrMapData(this.props.arrMapData_DADTXD));
      }
    }

    // if ((this.state.listLayers_DADTXD !== prevState.listLayers_DADTXD) ||
    //   (this.state.listLayers_GT !== prevState.listLayers_GT) ||
    //   (this.state.listLayers_KHQHSDD !== prevState.listLayers_KHQHSDD) ||
    //   (this.state.isDoneConstructMap !== prevState.isDoneConstructMap) ||
    //   (this.props.arrMapData !== prevProps.arrMapData) ||
    //   (this.props.mapData !== prevProps.mapData)
    // ) {
    //   console.log("THIS SHIT RUN OUTSIDE");
    //   if (this.state.isDoneConstructMap &&
    //     (
    //       // Điều kiện như này để đảm bảo chỉ chạy cái if này duy nhất 1 lần.
    //       (
    //         (
    //           this.props.match.params.id == planningIdInfo.planningId_KHQHSDD ||
    //           this.props.match.params.id == planningIdInfo.planningId_DADTXD ||
    //           this.props.match.params.id == planningIdInfo.planningId_GT
    //         )
    //         &&
    //         (
    //           (this.state.listLayers_KHQHSDD.length > 0 && this.state.listLayers_GT.length > 0 && this.state.listLayers_DADTXD.length == 0) ||
    //           (this.state.listLayers_KHQHSDD.length == 0 && this.state.listLayers_GT.length > 0 && this.state.listLayers_DADTXD.length > 0) ||
    //           (this.state.listLayers_KHQHSDD.length > 0 && this.state.listLayers_GT.length == 0 && this.state.listLayers_DADTXD.length > 0)
    //         )
    //       )
    //       ||
    //       (
    //         (
    //           this.props.match.params.id != planningIdInfo.planningId_KHQHSDD &&
    //           this.props.match.params.id != planningIdInfo.planningId_GT &&
    //           this.props.match.params.id != planningIdInfo.planningId_DADTXD
    //         ) &&
    //         (
    //           this.state.listLayers_KHQHSDD.length > 0 && this.state.listLayers_GT.length > 0 && this.state.listLayers_DADTXD.length > 0
    //         )
    //       )
    //     )
    //   ) {
    //     console.log("THIS SHIT RUN");

    //     this.setListLayers([
    //       // ...this.state.listLayers,
    //       ...ExtractListLayerArray(this.props.mapData),
    //       ...ExtractListLayerArrayFromArrMapData(this.props.arrMapData),
    //       ...this.state.listLayers_KHQHSDD,
    //       ...this.state.listLayers_GT,
    //       ...this.state.listLayers_DADTXD,
    //     ]);
    //   }
    // }

    if (this.state.isDoneConstructMap === true && (this.state.isDoneConstructMap !== prevState.isDoneConstructMap || this.state.listLayers_DADTXD !== prevState.listLayers_DADTXD)) {
      console.log("state listLayers_DADTXD change", this.state.listLayers_DADTXD);
      this.setState(prevState => ({
        listLayers: [...prevState.listLayers, ...this.state.listLayers_DADTXD]
      }));
    }
    if (this.state.isDoneConstructMap === true && (this.state.isDoneConstructMap !== prevState.isDoneConstructMap || this.state.listLayers_GT !== prevState.listLayers_GT)) {
      console.log("state listLayers_GT change", this.state.listLayers_GT);
      this.setState(prevState => ({
        listLayers: [...prevState.listLayers, ...this.state.listLayers_GT]
      }));
    }
    if (this.state.isDoneConstructMap === true && (this.state.isDoneConstructMap !== prevState.isDoneConstructMap || this.state.listLayers_KHQHSDD !== prevState.listLayers_KHQHSDD)) {
      console.log("state listLayers_KHQHSDD change", this.state.listLayers_KHQHSDD);
      this.setState(prevState => ({
        listLayers: [...prevState.listLayers, ...this.state.listLayers_KHQHSDD]
      }));
    }
    if (this.state.isDoneConstructMap === true && (this.state.isDoneConstructMap !== prevState.isDoneConstructMap || this.props.arrMapData !== prevProps.arrMapData)) {
      console.log("state listLayers arrMapData change", this.props.arrMapData);
      this.setState(prevState => ({
        listLayers: [...prevState.listLayers, ...ExtractListLayerArrayFromArrMapData(this.props.arrMapData)]
      }));
    }
    if (this.state.isDoneConstructMap === true && (this.state.isDoneConstructMap !== prevState.isDoneConstructMap || this.props.mapData !== prevProps.mapData)) {
      console.log("state listLayers mapData change", this.props.mapData);
      this.setState(prevState => ({
        listLayers: [...prevState.listLayers, ...ExtractListLayerArray(this.props.mapData)]
      }));
    }

    if (this.state.listLayers !== prevState.listLayers) {
      console.log("state listLayers change", this.state.listLayers);
    }
  }

  componentWillUnmount() {
    this.props.SetDisplayMaptoolPanel(false);
    this.props.SetDisplayInfomationPopup(false);
    this.props.ResetMapData();
  }

  render() {
    // console.log("Planning : ", this.props.arrMapData);
    const isLeftNavbarHide = this.state.isLeftNavbarHide;
    // const isSmallSize = window.innerWidth < 1024;
    return (
      // <ToggleBottomProvider>
      //   <TopRightPanelProvider>
      //     <AddContentBottomPanelProvider>
      //       <IsHideFilterBoxProvider>
      <div className="planning-map-view-container position-relative">

        {
          // this.props.isDesktopOrLaptop &&
          !isMobile &&
          <>
            <Suspense fallback={<div>Page is Loading, please wait...</div>}>
              <div className="position-absolute map-tools-container">
                <div className="position-relative">
                  <RenderFilterInformationPopup
                    listGroupLayer={ExtracLayerListGroup(this.props.mapData)}
                    listLayer={ExtractListLayerArray(this.props.mapData)}

                  />
                </div>
                <MapToolsPanel
                  openFIlterPopup={this.openFilterInfomationPopup}
                  map={this.state.mapPlanning}
                />
              </div>

              {
                this.props.hasLoadedMapData && this.props.mapData.name &&
                <TopRightPanelView
                  baseMapList={ExtractBasemapList(this.props.mapData)}
                  planningId={this.state.mapPlanning}
                  setPlanningModelInMap={this.setPlanningMap}
                />
              }
              <div id="left-custom-navbar" className="left-custom-navbar position-absolute">
                {this.props.hasLoadedMapData && this.props.mapData.name && (
                  <LeftMenuView
                    planningModel={this.state.planningModel}
                    planningId={this.state.mapPlanning}
                    listGroupLayer={this.props.dataToggleLayer}
                    isLeftNavbarHide={isLeftNavbarHide}
                    toggleStateIsLeftNavbarHide={() =>
                      this.setState({
                        isLeftNavbarHide: !this.state.isLeftNavbarHide,
                      })
                    }
                  />
                )}
              </div>
              <GuideUseMap modalHeightStyle={this.state.modalHeightStyle} />
            </Suspense>
          </>
        }

        {isMobile ? (
          <MenuMobile
            {...this.props}
            planningModel={this.state.planningModel}
            planningId={this.state.mapPlanning}
            listGroupLayer={this.props.dataToggleLayer}
            isLeftNavbarHide={isLeftNavbarHide}
            toggleStateIsLeftNavbarHide={() =>
              this.setState({
                isLeftNavbarHide: !this.state.isLeftNavbarHide,
              })
            }
          />
        ) : (
          <ListFooter
            isLeftNavbarHide={isLeftNavbarHide}
          />
        )}

        {/* footer-menu */}




        {/* {
                  this.props.isTabletOrMobile &&
                  <>
                    <Suspense fallback={<div>Page is Loading, please wait...</div>}>
                      <TopRightPanelMobile
                        baseMapList={ExtractBasemapList(this.props.mapData)}
                        planningId={this.state.mapPlanning}
                        setPlanningModelInMap={this.setPlanningMap}
                      />

                      <BottomToggleMobile />

                      <BottomPanelMobile
                        planningId={this.state.mapPlanning}
                      />

                      <RenderFilterInformationPopupMobile
                        listGroupLayer={ExtracLayerListGroup(this.props.mapData)}
                        listLayer={ExtractListLayerArray(this.props.mapData)}
                      />

                      <GuideUseModalMobile modalHeightStyle={this.state.modalHeightStyle} />
                    </Suspense>
                  </>
                } */}

        {this.props.hasLoadedMapData && this.props.mapData.name && (
          <OpenlayersMapView
            defaultBaseMapUrl={ExtractSelectedBasemap(this.props.mapData)}
            defaultCenter={ExtractCenterArray(this.props.mapData)}
            defaultZoom={this.props.mapData.zoom}
            projection={this.props.mapData.projection}
            maxZoom={this.props.mapData.max_zoom}
            minZoom={this.props.mapData.min_zoom}
            extent={ExtractExtentArray(this.props.mapData)}
            listLayer={this.state.listLayers}
            planningModel={this.state.planningModel}
            listGroupLayer={ExtracLayerListGroup(this.props.mapData)}
            coordinate={this.props.location}
            setIsDoneConstructMap={(data) => this.setIsDoneConstructMap(data)}
            planningId={parseInt(this.state.mapPlanning)}

            //props for assissive chip in map view
            layerGroupsPlace={this.state.layerGroups}
            layerGroups_KHQHSDD={this.state.layerGroups_KHQHSDD}
            layerGroups_GT={this.state.layerGroups_GT}
            layerGroups_DADTXD={this.state.layerGroups_DADTXD}
            fixedDataGroupLayers={this.state.fixedDataGroupLayersState}
            setFixedDataGroupLayers={(data: any) => this.setFixedDataGroupLayersState(data)}
            idPlanningToHide={this.state.mapPlanning}
          />
        )}

        {this.props.hasLoadedMapData && !this.props.mapData.name && (
          <div
            className="modal d-block"
            id="modalNotHaveDataToDisplay"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Dữ liệu không có sẵn để hiển thị
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() =>
                      document
                        .getElementById("modalNotHaveDataToDisplay")
                        ?.classList.remove("d-block")
                    }
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  Lỗi: Bản đồ của dự án chưa được khởi tạo, vui lòng liên hệ với
                  Admin
                </div>
                <div className="modal-footer text-center">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() =>
                      document
                        .getElementById("modalNotHaveDataToDisplay")
                        ?.classList.remove("d-block")
                    }
                  >
                    Đóng
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        <PreviewImgModal />
        {/* {
                  this.props.isIdNotExist && <div
                  className="modal d-block"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          Dữ liệu không có sẵn để hiển thị
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={() => {
                            const invisLink = document.getElementById("invisbile-link-to-SyntheticPlanning");
                            invisLink?.click();
                          }}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        Lỗi: Đồ án không tồn tại, vui lòng liên hệ với Admin
                      </div>
                      <div className="modal-footer text-center">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => {
                            const invisLink = document.getElementById("invisbile-link-to-SyntheticPlanning");
                            invisLink?.click();
                          }}
                        >
                          Trở về tổng hợp quy hoạch
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                }

                <Link
                  to={UrlCollect.SyntheticPlanning}
                  style={{display: 'none'}}
                  id="invisbile-link-to-SyntheticPlanning"
                /> */}

      </div>
      //       </IsHideFilterBoxProvider>
      //     </AddContentBottomPanelProvider>
      //   </TopRightPanelProvider>
      // </ToggleBottomProvider>
    );
  }
}

const mapStateToProps = (state: any) => ({
  hasLoadedMapData: state.mapData.hasLoadingData,
  isIdNotExist: state.mapData.isIdNotExist,
  mapData: state.mapData.mapData,
  planningRelationShips: state.mapData.planningRelationShips,
  arrMapData: state.mapData.arrMapData,
  arrMapData_KHQHSDD: state.mapData.arrMapDataKHQHSDD,
  arrMapData_GT: state.mapData.arrMapDataGT,
  arrMapData_DADTXD: state.mapData.arrMapDataDADTXD,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      GetMapDetail: MapDataStore.GetMapDetailByPlanningId,
      ResetMapData: MapDataStore.ResetMapData,
      GetArrMapDetail: MapDataStore.GetArrMapDetailByPlanningId,
      SetDataToggleLayer: MapToolDataStore.SetDataToggleLayer,
      SetDisplayMaptoolPanel: SetDisplayMaptoolPanel,
      SetDisplayInfomationPopup: SetDisplayInfomationPopup,
      GetListPlanningRelationByPlanningId:
        MapDataStore.GetListPlanningRelationByPlanningId,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(HousingAndEstateMapView);
