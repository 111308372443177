const env = process.env.NODE_ENV || "development";

const apiEnvironment = {
  development: {
    api: "https://hanam-api.cgis.asia/",
    domainUserSide: "http://localhost:3000",
    domainAdminSide: "https://hanam-admin.cgis.asia",
    domainName: "",
    doMainNameGeoServer: "https://geo.cgis.asia/geoserver/wfs",
    doMainNameGeoServerWMS: "https://geo.cgis.asia/geoserver/hanam/wms/",
    datastore: "hanam",
  },

  production: {
    api: "https://hanam-api.cgis.asia/",
    domainUserSide: "https://hanam.cgis.asia",
    domainAdminSide: "https://hanam-admin.cgis.asia",
    domainName: "hanam.cgis.asia",
    doMainNameGeoServer: "https://geo.cgis.asia/geoserver/wfs",
    doMainNameGeoServerWMS: "https://geo.cgis.asia/geoserver/hanam/wms/",
    datastore: "hanam",
  },
};

module.exports = apiEnvironment[env];

// const env = process.env.NODE_ENV || "development";

// const apiEnvironment = {
//   development: {
//     api: "https://pleiku-api.cgis.asia/",
//     domainUserSide: "http://localhost:3000",
//     domainAdminSide: "https://quyhoachpleiku-admin.gialai.gov.vn",
//     domainName: "",
//     doMainNameGeoServer: "https://geo.cgis.asia/geoserver/wfs",
//     doMainNameGeoServerWMS: "https://geo.cgis.asia/geoserver/pleiku/wms",
//     datastore: "pleiku",
//   },

//   production: {
//     api: "https://pleiku-api.cgis.asia/",
//     domainUserSide: "https://pleiku.cgis.asia",
//     domainAdminSide: "https://pleik-adminu.cgis.asia",
//     domainName: "pleiku.cgis.asia",
//     doMainNameGeoServer: "https://geo.cgis.asia/geoserver/wfs",
//     doMainNameGeoServerWMS: "https://geo.cgis.asia/geoserver/pleiku/wms/",
//     datastore: "pleiku",
//   },
// };

// module.exports = apiEnvironment[env];