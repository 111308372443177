import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@material-ui/core";
import TableSortLabel from '@material-ui/core/TableSortLabel';
import dateformat from "dateformat";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import QrCodeScannerIcon from '@material-ui/icons/CropFree';
import FooterPagination from "./pagination-footer/pagination-footer";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    tableContainer: {
        maxHeight: 275,
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
    appBar: {
        position: "relative",
        backgroundColor: "#00923F",
    },
    title: {
        marginLeft: theme.spacing(0),
        flex: 1,
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

const housingProjectTableCell = [
    { id: 'name', hideSortIcon: true, isSort: false, label: 'Tên dự án', width: '' },
    { id: 'address', hideSortIcon: true, isSort: false, label: 'Địa chỉ', width: '' },
    { id: 'projectStatusName', hideSortIcon: true, isSort: false, label: 'Tình trạng', width: '' },
    { id: 'investorName', hideSortIcon: true, isSort: false, label: 'Chủ đầu tư', width: '' },
    { id: 'approvalUnit', hideSortIcon: false, isSort: false, label: 'Đợn vị phê duyệt', width: '' },
    { id: 'constructionLicense', hideSortIcon: false, isSort: false, label: 'Cấp phép xây dựng', width: '' },
    { id: 'areaTypeId', hideSortIcon: true, isSort: false, label: 'Khu vực', width: '' },
    { id: 'averageRentalPrice', hideSortIcon: true, isSort: false, label: 'Giá thuê bình quân', width: '' },
]

const RealEstateProjectTableCell = [
    { id: 'ProjectName', hideSortIcon: true, isSort: false, label: 'Tên dự án', width: '' },
    { id: 'address', hideSortIcon: true, isSort: false, label: 'Địa chỉ', width: '' },
    { id: 'projectStatusName', hideSortIcon: true, isSort: false, label: 'Tình trạng', width: '' },
    { id: 'investorName', hideSortIcon: true, isSort: false, label: 'Chủ đầu tư', width: '' },
    { id: 'approvalUnit', hideSortIcon: false, isSort: false, label: 'Đơn vị phê duyệt', width: '' },
    { id: 'AreaSize', hideSortIcon: false, isSort: false, label: 'Quy mô diện tích', width: '' },
    { id: 'TotalInvestment', hideSortIcon: true, isSort: false, label: 'Tổng mức đầu tư', width: '' },
    { id: 'AverageSalesPrice', hideSortIcon: true, isSort: false, label: 'Giá bán bình quân', width: '' },
    { id: 'AverageRentalPrice', hideSortIcon: true, isSort: false, label: 'Giá thuê bình quân', width: '' },
]

const constructionTableCell = [
    { id: 'ProjectName', hideSortIcon: true, isSort: false, label: 'Tên công trình', width: '' },
    { id: 'RealEstateProjectId', hideSortIcon: true, isSort: false, label: 'Dự án', width: '' },
    { id: 'ConstructionTypeId', hideSortIcon: true, isSort: false, label: 'Loại công trình', width: '' },
    { id: 'AreaTotal', hideSortIcon: true, isSort: false, label: 'Tổng diện tích', width: '' },
    { id: 'Status', hideSortIcon: false, isSort: false, label: 'Tình trạng', width: '' },
    { id: 'NumberOfHouse', hideSortIcon: false, isSort: false, label: 'Số lượng căn hộ', width: '' },
    { id: 'AverageSalesPrice', hideSortIcon: true, isSort: false, label: 'Giá bán bình quân', width: '' },
    { id: 'AverageRentalPrice', hideSortIcon: true, isSort: false, label: 'Giá thuê bình quân', width: '' },
]

const exchangeTableCell = [
    { id: 'RealEstateExchangeName', hideSortIcon: true, isSort: false, label: 'Tên sàn', width: '' },
    { id: 'Address', hideSortIcon: true, isSort: false, label: 'Địa chỉ', width: '' },
    { id: 'LegaRepresentative', hideSortIcon: true, isSort: false, label: 'Đại diện', width: '' },
    { id: 'Status', hideSortIcon: true, isSort: false, label: 'Tình trạng', width: '' },
    { id: 'DateOfEstablishment', hideSortIcon: false, isSort: false, label: 'Ngày thành lập', width: '' },
    { id: 'DateOfDissolution  ', hideSortIcon: false, isSort: false, label: 'Ngày giải thể', width: '' },
]


const ListTable = (props) => {
    const { isMobile, data, orderBy, order, pageIndex, setPageIndex, pagination, tableType, getDetailAction } = props;
    const classes = useStyles();

    const getHeaderTable = (type) => {
        switch (type) {
            case 1:
                return housingProjectTableCell;
            case 2:
                return RealEstateProjectTableCell;
            case 3:
                return constructionTableCell;
            case 4:
                return exchangeTableCell;
            default:
                return [];
        }
    }

    return (
        <div>
            <div className="list-booking">
                <div className={classes.root}>
                    <Paper className={classes.paper}>
                        <TableContainer className={classes.tableContainer}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        {getHeaderTable(tableType).map((item, index) => (
                                            (!isMobile || index < 4) && <TableCell
                                                key={item.id}
                                                className={
                                                    "pt-3 pb-3 text-nowrap " +
                                                    (item.id === "title" ? "MuiTableCell-freeze header_title" : "")
                                                }
                                            >
                                                <TableSortLabel
                                                    active={orderBy === item.id}
                                                    direction={orderBy === item.id ? order : 'asc'}
                                                    // onClick={item.isSort ? createSortHandler(item.id) : null}
                                                    hideSortIcon={item.hideSortIcon}>
                                                    {item.label}
                                                </TableSortLabel>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data && data.length > 0 && data.map((item) => {
                                        if (tableType == 1)
                                            return (
                                                <TableRow className="cursor-pointer" hover tabIndex={-1} key={item.id} onClick={() => getDetailAction(item.id)}>
                                                    {/* <TableCell className="cell_img">
                                        <img src={apiConfig.api + item.documentPath} alt={item.title} />
                                    </TableCell> */}
                                                    <TableCell className="MuiTableCell-freeze">
                                                        <div className="cell_title">
                                                            {item.projectName}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>{item.address}</TableCell>
                                                    <TableCell>{item.projectStatusName}</TableCell>
                                                    <TableCell>{item.investorName}</TableCell>
                                                    {!isMobile && <TableCell>{item.averageRentalPrice}</TableCell>}
                                                    {!isMobile && <TableCell>{item.approvalUnit}</TableCell>}
                                                    {!isMobile && <TableCell>{item.areaTypeName}</TableCell>}
                                                    {!isMobile && <TableCell>{item.constructionLicense}</TableCell>}
                                                </TableRow>
                                            )
                                        if (tableType == 2)
                                            return (
                                                <TableRow className="cursor-pointer" hover tabIndex={-1} key={item.id} onClick={() => getDetailAction(item.id)}>
                                                    <TableCell className="MuiTableCell-freeze">
                                                        <div className="cell_title">
                                                            {item.projectName}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>{item.address}</TableCell>
                                                    <TableCell>{item.constructionProgress}</TableCell>
                                                    <TableCell>{item.investorName}</TableCell>
                                                    {!isMobile && <TableCell>{item.approvalUnit}</TableCell>}
                                                    {!isMobile && <TableCell>{item.areaSize}</TableCell>}
                                                    {!isMobile && <TableCell>{item.totalInvestment}</TableCell>}
                                                    {!isMobile && <TableCell>{item.averageSalesPrice}</TableCell>}
                                                    {!isMobile && <TableCell>{item.averageRentalPrice}</TableCell>}
                                                </TableRow>
                                            )
                                        if (tableType == 3)
                                            return (
                                                <TableRow className="cursor-pointer" hover tabIndex={-1} key={item.id} onClick={() => getDetailAction(item.id)}>
                                                    <TableCell className="MuiTableCell-freeze">
                                                        <div className="cell_title">
                                                            {item.projectName}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>{item.realEstateProjectName}</TableCell>
                                                    <TableCell>{item.constructionProgress}</TableCell>
                                                    <TableCell>{item.totalArea}</TableCell>
                                                    {!isMobile && <TableCell>{item.areaSize}</TableCell>}
                                                    {!isMobile && <TableCell>{item.numberHouse}</TableCell>}
                                                    {!isMobile && <TableCell>{item.averageSalesPrice}</TableCell>}
                                                    {!isMobile && <TableCell>{item.averageRentalPrice}</TableCell>}
                                                </TableRow>
                                            )
                                        if (tableType == 4)
                                            return (
                                                <TableRow className="cursor-pointer" hover tabIndex={-1} key={item.id} onClick={() => getDetailAction(item.id)}>
                                                    <TableCell className="MuiTableCell-freeze">
                                                        <div className="cell_title">
                                                            {item.realEstateExchangeName}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>{item.address}</TableCell>
                                                    <TableCell>{item.legalRepresentative}</TableCell>
                                                    <TableCell>{`${item.status ? 'Đang hoạt động' : 'Đã giải thể'}`}</TableCell>
                                                    {!isMobile && <TableCell>{dateformat(item.dateEstablishment, "dd-mm-yyyy")}</TableCell>}
                                                    {!isMobile && <TableCell>{item.dateDissolution ? dateformat(item.dateDissolution, "dd-mm-yyyy") : ''}</TableCell>}
                                                </TableRow>
                                            )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {pagination.totalItemCount && (
                            <FooterPagination
                                showFirstLastButton
                                setPageIndex={setPageIndex}
                                currentPage={pageIndex}
                                totalPage={pagination.pageCount}
                                totalItemCount={pagination.totalItemCount}
                                pageLimit={pagination.pageSize}
                            />
                        )}
                    </Paper>
                </div>
            </div>
        </div>
    )
}

export default ListTable;