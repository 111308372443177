export const planningIdInfo = {
    planningId_POINT_MAP : 16,
    planningId_HOME_MAP : 9,
    planningId_KHQHSDD : 8,
    planningId_GT : 14,
    planningId_DADTXD : 13,
    
    HOME_MAP_LAYER_ID : 30,
    HOME_MAP_GEO_LAYER_NAME : 'diaphanpleiku',
    PROJECT_MAP_LAYER_ID: 84,
} 