export default interface mapInfoMationRelatedModel {
  layer: LayerSettingsModel;
  planning: planningModel;
}

export interface listPlanningLayerData {
  layers: LayerSettingsModel[];
  planning: PlanningModel;
}

export interface planningModel {
  name: string;
  id: number;
}

export interface SingleMapInfoMationModel {
  label: string;
  value: string;
}

export interface LayerSettingsModel {
  geo_layer_name: string;
  id: number;
  is_check: boolean;
  layer_type: string;
  layer_category_id: number;
  layer_kind_id: number;
  level: number;
  max_zoom: number;
  min_zoom: number;
  name: string;
  table: string;
  wms: string;
  wms_external: boolean;
  z_index: number;
  display: any;
  filter: any;
  display_name: any;
  filter_name: any;
  opacity: string;
  layerRealationships: LayerSettingsModel[],
  style: string;
  files: any;
}

export interface PlanningModel {
  id: number;
  name: string;
  planningTypeId: number;
}

export const createMapInfoMationRelatedModel = (listPlanningLayerData: listPlanningLayerData[]) => {
  if (listPlanningLayerData.length > 0) {
    let data = listPlanningLayerData.map((item: listPlanningLayerData) => {
      return {
        layer: item.layers.filter((layer: LayerSettingsModel) => layer.layer_kind_id == 1)[0],
        planning: item.planning
      }
    })
    return data
  } else {
    return []
  }
}
