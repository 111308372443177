import React, { useState, useEffect, useRef } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as MapDataStore from "../../../../redux/store/map-data/map-data.store";
import { APIUrlDefault } from "../../../../utils/configuration";
import GaleryCarousel from "./garely-carousel"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMap } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "@material-ui/core";

function Planning(props) {
    const [planningRelatedId, setPlanningRelatedId] = useState();
    const [ishow, setIsShow] = useState(true);
    useEffect(() => {
        setIsShow(planningRelatedId ? true : false)
        console.log('khfkjdsdfsdjfl')
    }, [props.listMapInfomationsRelated])
    const savePreviewInfo = (previewLabel, imageSrc) => {
        const data = {
            previewLabel: previewLabel,
            imageSrc: imageSrc
        }
        props.savePreviewImage(data);
    }

    const getImageRow = (index, label, rowValue) => {
        return (
            <tr key={index}>
                <td colSpan="2">
                    <div className="image-title">{label}:</div>
                    <div
                        onClick={() => savePreviewInfo(label, rowValue)}
                        className="image-show"
                        data-toggle="modal"
                        data-target="#previewImage"
                    ><img src={rowValue} />
                    </div>
                </td>
            </tr>
        )
    }
    const getDataRow = (index, label, rowValue) => {
        return (
            <tr key={index}>
                <td className="row-label" width="50%">{label}</td>
                <td width="50%">{rowValue}</td>
            </tr>
        )
    }
    const getRowData = (data, index) => {
        // console.log(data);
        if (data.label == 'id') return;
        const rowLabel = data.label;
        const rowValue = data.value;
        const arrRow = rowLabel.split('_');
        let label = data.label;
        let labelPrefix = '';
        if (Array.isArray(arrRow) && arrRow.length == 2) {
            labelPrefix = arrRow[0];
            label = arrRow[1];
        }

        if (label && label == 'avatar') {
            return getImageRow(index, "Hình ảnh", APIUrlDefault + rowValue);
        } if (label && label == 'images') {
            return <GaleryCarousel index={index} label={"Thư viện ảnh"} rowValue={rowValue} />
        } else {
            return getDataRow(index, label, rowValue);
        }
    }

    const handleClickToDisplayLayerRealted = (layer, planingId, isDisplay) => {
        if (planingId === planningRelatedId) {
            props.openLayerController.toggleDisplayPlanningRelated(layer, ishow)
        } else {
            props.openLayerController.toggleDisplayPlanningRelated(layer, !ishow)
        }
        setIsShow(!ishow)
        setPlanningRelatedId(planingId)
    }

    if (!props.isMobile) return (
        <>
            <details id="mapInfomationContainer">
                <summary>
                    <p className="title">Thông tin đối tượng</p>
                    <img
                        className="position-absolute icon-dropdow right-position"
                        src={require("../../../../assets/icon/arrow-down.png")}
                        alt="arrow-down"
                    />
                    <img
                        className="position-absolute icon-dropright right-position"
                        src={require("../../../../assets/icon/arrow-right.png")}
                        alt="arrow-right"
                    />
                </summary>
                <div className="info-table">
                    {props.listMapInfomations?.length > 0 ? (
                        <table>
                            <thead>
                                <th className="row-label" width="50%">Tên trường</th>
                                <th width="50%">Giá trị</th>
                            </thead>
                            <tbody>
                                {props.listMapInfomations?.map((data, index) => {
                                    if (data.value) {
                                        return getRowData(data, index);
                                    }
                                }
                                )}
                            </tbody>
                        </table>
                    ) : (
                        <p className="pt-3 text-center">Không có dữ liệu</p>
                    )}
                </div>
            </details>
            {props.listMapInfomations && props.listMapInfomationsRelated.length > 0 && (
                <div className="info_related_table">
                    <p className="title mb-1">Tra cứu thông tin tại địa điểm này</p>
                    {props.listMapInfomationsRelated.map(item => (
                        <div key={item.planning.id} id={`mapInfomationRelatedContainer_${item.planning.id}`} className="mb-2">
                            <div className="view_planning_layer_related">
                                <div
                                    onClick={() => handleClickToDisplayLayerRealted(item.layer, item.planning.id)}
                                    className="title_related_layer"
                                >
                                    {item.planning.name}
                                </div>
                                <Tooltip title="Xem đồ án quy hoạch">
                                    <a target="_blank" href={`/ban-do-quy-hoach/${item.planning.id}`}>
                                        <FontAwesomeIcon icon={faMap} className="icon-link" />
                                    </a>
                                </Tooltip>
                            </div>
                            {props.listMapInfomationsRelatedTable?.length > 0 && (
                                <div className="info-table">
                                    <table>
                                        <thead>
                                            <th className="row-label" width="50%">Tên trường</th>
                                            <th width="50%">Giá trị</th>
                                        </thead>
                                        <tbody>
                                            {props.listMapInfomationsRelatedTable?.map((data, index) => {
                                                if (data.value) {
                                                    return getRowData(data, index);
                                                }
                                            }
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </>
    );
    else return (
        <div className="info-table">
            {props.listMapInfomations?.length > 0 ? (
                <table>
                    <thead>
                        <th className="row-label" width="50%">Tên trường</th>
                        <th width="50%">Giá trị</th>
                    </thead>
                    <tbody>
                        {props.listMapInfomations?.map((data, index) => {
                            if (data.value) {
                                return getRowData(data, index);
                            }
                        }
                        )}
                    </tbody>
                </table>
            ) : (
                <p className="pt-3 text-center">Vui lòng chọn đối tượng</p>
            )}
        </div>
    )
}

const mapStateToProps = (state) => ({
    openLayerController: state.openlayer.openLayerController,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            savePreviewImage: MapDataStore.SavePreviewImage,
        }, dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(Planning);